import React from 'react'
import './ModalQuestionManage.css'

export default function ModalDeleteQuestion({ onConfirm, onCancel }) {
    return (
        <div className="modal-question-manage-overlay">
            <div className="modal-question-manage-content" onClick={(e) => e.stopPropagation()} style={{height: "unset", minHeight: "unset"}}>
                <div className="modal-question-manage-header">
                    <h3>Delete Question</h3>
                </div>
                <div className="modal-question-manage-body">
                    <p>Are you sure you want to delete this question?</p>
                </div>
                <div className="modal-question-manage-footer">
                    <button className="modal-question-manage-cancel" onClick={onCancel}>
                        No
                    </button>
                    <button className="modal-question-manage-save" onClick={onConfirm}>
                        Yes
                    </button>
                </div>
            </div>
        </div>
    )
}
