import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { requestPasswordReset } from '../../../api';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitted(true);
      setError('');
      try {
        await requestPasswordReset(email);
      } catch (error) {
        console.error('Error requesting password reset:', error);
        setError('An error occurred while requesting password reset. Please try again.');
        setIsSubmitted(false);
      }
    };
  
    if (isSubmitted) {
      return (
        <div className="login-container reset-password-container">
          <h2>Password Reset Request Sent</h2>
          <p>If an account exists for {email}, you will receive an email with further instructions.</p>
          <Link to="/login" className="reset-password-submit">Return to Login</Link>
        </div>
      );
    }
  
    return (
      <div className="login-container reset-password-container">
        <h2>Forgot Password</h2>
        <form className="login-form reset-password-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit" className="reset-password-submit">Reset Password</button>
        </form>
        <p>
          Remember your password? <Link to="/login">Log in</Link>
        </p>
      </div>
    );
  }
