import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { getFormTemplates, createFormFromTemplate, createNewForm } from '../../api';
import { useOrganization } from '../../context/OrganizationContext';
import ModalOrgNewForm from './ModalOrgNewForm';
import Loader from '../loaders/Loader';
import './CreateNewForm.css';

export default function CreateNewForm() {
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const { organization } = useOrganization();
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await getFormTemplates();
                if (response.data?.templates) {
                    setTemplates(response.data.templates);
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
                navigate(`/o/${organizationId}/forms`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTemplates();
    }, [organizationId, navigate]);

    const handleTemplateSelect = async (templateId) => {
        setIsLoading(true);
        try {
            const response = await createFormFromTemplate(organizationId, templateId);
            if (response.data?.form?._id) {
                navigate(`/o/${organizationId}/forms/${response.data.form._id}`);
            } else {
                navigate(`/o/${organizationId}/forms`);
            }
        } catch (error) {
            console.error('Error creating form from template:', error);
            navigate(`/o/${organizationId}/forms`);
        }
    };

    const handleCreateFromScratch = (formName) => {
        createNewForm(organizationId, { title: formName, status: "draft", structure: [] })
            .then((res) => {
                if (res.data?.form?._id) {
                    navigate(`/o/${organizationId}/forms/${res.data.form._id}`);
                } else {
                    navigate(`/o/${organizationId}/forms`);
                }
            })
            .catch(() => {
                navigate(`/o/${organizationId}/forms`);
            });
    };

    if (isLoading) {
        return <div className="create-new-form-loader"><Loader /></div>;
    }

    return (
        <div className="create-new-form">
            <div className="create-new-form-header">
                <button 
                    className="create-new-form-back" 
                    onClick={() => navigate(`/o/${organizationId}/forms`)}
                >
                    <IoArrowBack />
                    {organization?.name || 'Organization'}
                </button>
            </div>

            <div className="create-new-form-content">
                <div className="create-new-form-title">
                    Select a template or{' '}
                    <span 
                        className="create-new-form-scratch"
                        onClick={() => setShowCreateModal(true)}
                    >
                        Start from scratch
                    </span>
                </div>

                <div className="create-new-form-templates">
                    {templates.map((template) => (
                        <div 
                            key={template.template_id}
                            className="create-new-form-template"
                            onClick={() => handleTemplateSelect(template.template_id)}
                        >
                            <h3>{template.template_name}</h3>
                            <p>{template.template_teaser}</p>
                        </div>
                    ))}
                </div>
            </div>

            {showCreateModal && (
                <ModalOrgNewForm 
                    createNewForm={handleCreateFromScratch}
                    closeForm={() => setShowCreateModal(false)}
                />
            )}
        </div>
    );
}
