import React from 'react'
import './footer.css'

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-inner">
                <p>© 2024 Tetherform. All rights reserved</p>
                <p>Tetherform is a product of <a href="https://tetheros.com" target="_blank" rel="noopener noreferrer">Tetheros</a></p>
            </div>
        </div>
    )
}
