import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInfoForm from './components/BasicInfoForm';
import AvailabilitySelector from './components/AvailabilitySelector';
import ConfirmationPage from './components/ConfirmationPage';
import ShareableLinkPage from './components/ShareableLinkPage';
import '../MeetingFinder.css';

export default function MeetingFinderCreator() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [pollId, setPollId] = useState(false);
    const [pollData, setPollData] = useState({
        name: '',
        email: '',
        eventName: '',
        duration: 30,
        selectedDates: [],
        selectedTimes: []
    });

    const handleBasicInfoSubmit = (basicInfo) => {
        setPollData(prev => ({ ...prev, ...basicInfo }));
        setCurrentStep(2);
    };

    const handleAvailabilitySubmit = (availabilityData) => {
        setPollData(prev => ({ ...prev, ...availabilityData }));
        setCurrentStep(3);
    };

    const handleConfirmationSubmit = (pollResponse) => {
        if (pollResponse.pollId) {
            setPollId(pollResponse.pollId)
            setCurrentStep(4);
        }
        
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        } else {
            navigate('/meeting-finder');
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <BasicInfoForm 
                        initialData={pollData}
                        onSubmit={handleBasicInfoSubmit}
                        onBack={handleBack}
                    />
                );
            case 2:
                return (
                    <AvailabilitySelector
                        initialData={pollData}
                        onSubmit={handleAvailabilitySubmit}
                        onBack={handleBack}
                    />
                );
            case 3:
                return (
                    <ConfirmationPage
                        pollData={pollData}
                        onSubmit={handleConfirmationSubmit}
                        onBack={handleBack}
                    />
                );
            case 4:
                return (
                    <ShareableLinkPage
                        pollData={pollData}
                        pollId={pollId}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="meeting-finder">
            <div className="meeting-finder-creator">
                {currentStep < 4 && (
                    <div className="meeting-finder-progress">
                        <div className="meeting-finder-progress-steps">
                            {[1, 2, 3].map(step => (
                                <div 
                                    key={step}
                                    className={`meeting-finder-progress-step ${step === currentStep ? 'active' : ''} ${step < currentStep ? 'completed' : ''}`}
                                >
                                    {step}
                                </div>
                            ))}
                        </div>
                        <div className="meeting-finder-progress-labels">
                            <span className={currentStep === 1 ? 'active' : ''}>Basic Info</span>
                            <span className={currentStep === 2 ? 'active' : ''}>Select Times</span>
                            <span className={currentStep === 3 ? 'active' : ''}>Confirm</span>
                        </div>
                    </div>
                )}
                {renderStep()}
            </div>
        </div>
    );
} 