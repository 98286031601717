import React, { useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import './FormResponseView.css';
import './FormBuilderOptions.css';
import FormResponseViewText from './form_response_charts/FormResponseViewText';
import { IoMdDownload } from "react-icons/io"

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
    ChartDataLabels,
    annotationPlugin,
    zoomPlugin
);

const FormResponseView = ({ responses, formName }) => {
    const processedData = useMemo(() => {
        if (!responses || responses.length === 0) {
            return [];
        }

        const questionMap = new Map();

        responses.forEach(response => {
            response.answers.forEach(answer => {
                if (!answer.valid) return;

                if (!questionMap.has(answer.questionId)) {
                    questionMap.set(answer.questionId, {
                        questionText: answer.question_text,
                        questionType: answer.question_type,
                        answers: []
                    });
                }

                questionMap.get(answer.questionId).answers.push(answer.answer);
            });
        });
        return Array.from(questionMap.entries());
    }, [responses]);

    const downloadCSV = () => {
        // Create an object to store all questions
        const questions = {};

        // Populate the questions object
        responses.forEach(response => {
            response.answers.forEach(answer => {
                if (!answer.valid) return;
                questions[answer.questionId] = answer.question_text;
            });
        });

        // Create the CSV content
        let csvContent = 'ID,Date,' + 
            Object.values(questions)
                .map((q) => `"${q.replace(/"/g, '""')}"`)
                .join(',') + '\n';

        // Add each row of responses
        responses.forEach(response => {
            const submittedDate = new Date(response.submitted_at).toISOString().split('T')[0]; // Format as yyyy-mm-dd
            let row = `"${response._id}","${submittedDate}",`;
            
            row += Object.keys(questions)
                .map(questionId => {
                    const answer = response.answers.find(a => a.questionId === questionId)?.answer;
                    if (answer === undefined || answer === null) {
                        return '""';
                    }
                    if (Array.isArray(answer)) {
                        return `"${answer.join(', ').replace(/"/g, '""')}"`;
                    }
                    if (typeof answer === 'string') {
                        return `"${answer.replace(/"/g, '""')}"`;
                    }
                    return `"${answer}"`;
                })
                .join(',');
            
            csvContent += row + '\n';
        });

        // Create and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            const date = new Date()
                .toISOString()
                .split('T')[0]
                .replace(/-/g, ' '); // Format: YYYY MM DD
            const sanitizedFormName = formName.replace(/[^a-z0-9]/gi, ' ').trim();
            const fileName = `${date} ${sanitizedFormName}.csv`;
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const renderChart = (questionId, questionData) => {
        const { questionType } = questionData;

        switch (questionType) {
            case 'text':
                return <FormResponseViewText key={questionId} questionId={questionId} questionData={questionData} />;
            case 'rating':
                const { questionText, answers } = questionData;
                const ratingCounts = answers.reduce((acc, rating) => {
                    acc[rating] = (acc[rating] || 0) + 1;
                    return acc;
                }, {});

                const totalResponses = Object.values(ratingCounts).reduce((sum, count) => sum + count, 0);
                const averageScore = (
                    Object.entries(ratingCounts).reduce((sum, [rating, count]) => sum + rating * count, 0) / totalResponses
                ).toFixed(1);

                // Ensure the x-axis starts at 1 and includes all integers up to the maximum rating
                const maxRating = Math.max(...Object.keys(ratingCounts).map(Number));
                const sortedRatings = Array.from({ length: maxRating }, (_, i) => (i + 1).toString());

                const maxResponse = Math.max(...Object.values(ratingCounts));
                const yAxisMax = Math.ceil((maxResponse) / 5) * 5;

                const chartData = {
                    labels: sortedRatings,
                    datasets: [
                        {
                            label: 'Total Responses',
                            data: sortedRatings.map((rating) => ratingCounts[rating] || 0),
                            borderColor: '#309C83',
                            backgroundColor: '#309C83',
                            tension: 0.3,
                            pointRadius: 4,
                            pointBackgroundColor: '#309C83',
                            fill: false,
                        },
                    ],
                };

                return (
                    <div key={questionId} className="form-response-view-chart" style={{ position: 'relative' }}>
                        <div className="form-response-view-chart-header">
                            <h3>{questionText}</h3>
                            <div className="form-response-view-chart-average-score">{averageScore}</div>
                        </div>
                        <div className="chart-container" style={{ height: '300px', position: 'relative' }}>
                            <Line
                                data={chartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            type: 'linear',
                                            min: 1,
                                            max: maxRating,
                                            ticks: {
                                                stepSize: 1,
                                                precision: 0,
                                            },
                                            title: {
                                                display: true,
                                                text: 'Rating',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold',
                                                },
                                            },
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            max: yAxisMax,
                                            ticks: {
                                                stepSize: 1,
                                                precision: 0,
                                            },
                                            title: {
                                                display: true,
                                                text: 'Total Responses',
                                                font: {
                                                    size: 14,
                                                    weight: 'bold',
                                                },
                                            },
                                            grid: {
                                                color: 'rgba(255, 255, 255, 0.441)',
                                                lineWidth: 0.5,
                                            },
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: (context) => {
                                                    const value = context.parsed.y || 0;
                                                    const percentage = (
                                                        (value / totalResponses) *
                                                        100
                                                    ).toFixed(1);
                                                    return `${percentage}%`;
                                                },
                                            },
                                        },
                                        datalabels: {
                                            anchor: 'end',
                                            align: 'start',
                                            formatter: (value) => value,
                                            color: 'transparent',
                                            font: {
                                                weight: 'bold',
                                                size: 12,
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                );
            case 'multiple-choice':
            case 'select-multiple':
                const { questionText: mcQuestionText, answers: mcAnswers } = questionData;
                const choiceCounts = mcAnswers.flat().reduce((acc, choice) => {
                    acc[choice] = (acc[choice] || 0) + 1;
                    return acc;
                }, {});

                const labels = Object.keys(choiceCounts);
                const isVertical = labels.length > 3;

                // Calculate the axis maximum value based on the rule of thumb
                const values = Object.values(choiceCounts);
                const maxValue = Math.max(...values);
                const suggestedMax = maxValue;
                const axisMax = Math.ceil(suggestedMax / 5) * 5;

                const barChartData = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Responses',
                            data: values,
                            backgroundColor: '#309c83cd',
                        },
                    ],
                };


                return (
                    <div key={questionId} className="form-response-view-chart">
                        <h3>{mcQuestionText}</h3>
                        <div className="chart-container">
                            <Bar
                                data={barChartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    indexAxis: isVertical ? 'y' : 'x',
                                    scales: isVertical
                                        ? {
                                              x: {
                                                  beginAtZero: true,
                                                  max: axisMax,
                                                  ticks: {
                                                      stepSize: 1,
                                                      precision: 0,
                                                  },
                                                  title: {
                                                      display: true,
                                                      text: 'Total Responses',
                                                      font: {
                                                          size: 14,
                                                          weight: 'bold',
                                                      },
                                                  },
                                                  grid: {
                                                      color: 'rgba(255, 255, 255, 0.441)',
                                                      lineWidth: 0.5,
                                                  },
                                              },
                                              y: {
                                                  beginAtZero: true,
                                                  ticks: {
                                                      autoSkip: false,
                                                      maxRotation: 0,
                                                      minRotation: 0,
                                                  },
                                                  title: {
                                                      display: true,
                                                      text: 'Choice',
                                                      font: {
                                                          size: 14,
                                                          weight: 'bold',
                                                      },
                                                  },
                                              },
                                          }
                                        : {
                                              x: {
                                                  beginAtZero: true,
                                                  ticks: {
                                                      stepSize: 1,
                                                      precision: 0,
                                                  },
                                                  title: {
                                                      display: true,
                                                      text: 'Choice',
                                                      font: {
                                                          size: 14,
                                                          weight: 'bold',
                                                      },
                                                  },
                                                  grid: {
                                                      display: false,
                                                  },
                                              },
                                              y: {
                                                  beginAtZero: true,
                                                  max: axisMax,
                                                  ticks: {
                                                      stepSize: 1,
                                                      precision: 0,
                                                  },
                                                  title: {
                                                      display: true,
                                                      text: 'Total Responses',
                                                      font: {
                                                          size: 14,
                                                          weight: 'bold',
                                                      },
                                                  },
                                                  grid: {
                                                      color: 'rgba(255, 255, 255, 0.441)',
                                                      lineWidth: 1,
                                                  },
                                              },
                                          },
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        datalabels: {
                                            anchor: 'end',
                                            align: 'end',
                                            formatter: (value) => value,
                                            color: 'transparent',
                                            font: {
                                                weight: 'bold',
                                                size: 12,
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="form-response-view-container">
            <div className="form-builder-design-options-header-row">
                <h2>Responses</h2>
                <div className="form-builder-design-options-header-text-button" onClick={downloadCSV}>Download  <IoMdDownload style={{fontSize: "1.1rem"}}/></div>
            </div>
            <div className="form-response-view-grid">
                <div className="form-response-view-column">
                    {processedData
                        .slice(0, Math.ceil(processedData.length / 2))
                        .map(([questionId, questionData]) =>
                            renderChart(questionId, questionData)
                        )}
                </div>
                <div className="form-response-view-column">
                    {processedData
                        .slice(Math.ceil(processedData.length / 2))
                        .map(([questionId, questionData]) =>
                            renderChart(questionId, questionData)
                        )}
                </div>
            </div>
        </div>
    );
};

export default FormResponseView;
