import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { trial_plans } from '../../config/TRIAL_PLANS'
import { formatUSDCurrency, formatNumber } from '../../helpers/Helpers';
import './home.css'

export default function Pricing() {
    const navigate = useNavigate()
    const [isMonthly, setIsMonthly] = useState(true)
    return (
        <div className="home-pricing-section">
            <h2>Simple, Transparent Pricing</h2>
            <p>Choose the plan that fits your needs. All plans include a 14-day free trial.</p>
            <div className="billing-toggle-container">
                <div className="billing-toggle">
                    <button className={`toggle-button ${isMonthly ? 'active' : ''}`} onClick={() => setIsMonthly(true)}>Monthly</button>
                    <button className={`toggle-button ${!isMonthly ? 'active' : ''}`} onClick={() => setIsMonthly(false)}>Annual <span style={isMonthly ? {color: "var(--color-grey)"} : {}}>(16% off)</span></button>
                </div>
            </div>
            <div className="home-pricing-plans">
                {trial_plans.map((plan) => (
                    <div className="home-pricing-plan" key={plan.tetherform_plan_ref}>
                        <h4>
                            {formatNumber(plan.monthly_responses)}
                            <span>responses per month</span>
                        </h4>
                        <div className="home-pricing-plan-info">
                            <div style={{fontWeight: 600}}>{plan.display_name} plan</div>
                            {isMonthly ?
                            <div>
                                <div className="home-pricing-plan-info-costs">
                                    <span>{formatUSDCurrency(plan.monthly_price)} /mo</span>
                                    <span>Billed monthly</span>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="home-pricing-plan-info-costs">
                                    <span>{formatUSDCurrency(plan.annual_price/12)} /mo</span>
                                    <span>{formatUSDCurrency(plan.annual_price)} billed annually </span>
                                </div>
                            </div>
                            }
                        </div>
                        <button className="home-pricing-cta" onClick={() => navigate('/register')}>Get Started</button>
                    </div>
                ))}
            </div>
        </div>
    )
}
