import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

const sizeOptions = [
    { value: 'single', label: 'Single Line' },
    { value: 'paragraph', label: 'Paragraph' },
];

const maxLengthOptions = [
    { value: 280, label: 'Short (280 characters)' },
    { value: 1000, label: 'Medium (1,000 characters)' },
    { value: 3000, label: 'Long (3,000 characters)' },
];

const TextOptions = ({ options, onChange }) => {
    const handleChange = (field, value) => {
        onChange({ ...options, [field]: value });
    };

    return (
        <div className="text-options">
            <div className="modal-question-manage-main-information-section">
                <label className="modal-question-manage-option-label">Size</label>
                <div className="text-size-options">
                    {sizeOptions.map((size) => (
                        <button
                            key={size.value}
                            className={`text-size-button ${options.size === size.value ? 'selected' : ''}`}
                            onClick={() => handleChange('size', size.value)}
                        >
                            {size.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className="modal-question-manage-main-information-section">
                <label className="modal-question-manage-option-label">Max Length</label>
                <div className="modal-question-manage-selection-type-container">
                    <div className="modal-question-manage-type-select-container" style={{width: '100%'}}>
                        <select className="modal-question-manage-type-select" value={options.maxLength} onChange={(e) => handleChange('maxLength', parseInt(e.target.value))} style={{paddingLeft: "1rem" }}>
                            {maxLengthOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <FaChevronDown className="modal-question-manage-type-select-icon" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextOptions;