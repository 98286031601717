import React, { useRef, useReducer, useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './FormBuilder.css';
import FormBuilderDesignCanvas from './FormBuilderDesignCanvas';
import FormBuilderDesignOptions from './FormBuilderDesignOptions';
import FormBuilderNotifications from './FormBuilderNotifications';
import FormBuilderAnalytics from './FormBuilderAnalytics';
import FormResponseView from './FormResponseView';
import FormBuilderSchedule from './FormBuilderSchedule';
import FormSharing from './FormSharing';
import SplitButton from './SplitButton';
import PublishConfirmationModal from './PublishConfirmationModal';
import { FaShare, FaArrowLeft, FaRocket } from 'react-icons/fa';
import { FaCalendarCheck } from "react-icons/fa6";
import { MdMarkEmailUnread, MdMessage } from "react-icons/md";
import { RiSurveyFill } from "react-icons/ri";
import { AiFillFormatPainter, AiFillEye } from "react-icons/ai";
import { IoSettingsSharp, IoAnalyticsOutline } from "react-icons/io5";
import { getFormDraft, updateFormDraft, getFormResponses } from '../../api';
import Loader from '../loaders/Loader';
import { publishedFormHasBasicDifferences } from '../../helpers/Helpers';

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FORM_DATA':
            return { ...state, ...action.payload };
        case 'SET_SELECTED_NAV':
            return { ...state, selectedNav: action.payload };
        case 'SET_SAVE_STATE':
            return { ...state, saveState: action.payload };
        case 'SET_FORM_TITLE':
            return { ...state, title: action.payload };
        case 'SET_QUESTIONS':
            return { ...state, structure: action.payload };
        case 'ADD_QUESTION':
            if (action.payload.index !== undefined) {
                const newQuestions = [...state.structure];
                newQuestions.splice(action.payload.index, 0, action.payload.question);
                return { ...state, structure: newQuestions };
            } else {
                return { ...state, structure: [...state.structure, action.payload.question] };
            }
        case 'UPDATE_QUESTION':
            return { ...state, structure: state.structure.map(q => q.id === action.payload.id ? action.payload : q) };
        case 'DELETE_QUESTION':
            return { ...state, structure: state.structure.filter(q => q.id !== action.payload) };
        case 'UPDATE_DESIGN':
            return { ...state, ...action.payload };
        case 'UPDATE_NOTIFICATIONS':
            return { ...state, ...action.payload };
        case 'UPDATE_SCHEDULE':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
const initialState = {
    title: 'Untitled Form',
    structure: [],
    status: 'draft',
    saveState: 'Draft saved',
    selectedNav: 'questions',
    design_primary_color: '#309C83',
    design_dark_mode: false,
    notification_emails: [],
    notify_on_response: false,
    notify_on_schedule_end: false,
    notify_on_date: false,
    notification_date: null,
    notification_includes: [],
    schedule_start: null,
    schedule_end: null,
};

export default function FormBuilder() {
    const navigate = useNavigate()
    const timerRef = useRef(null);
    const changesPendingRef = useRef(false);
    const { organizationId, formId } = useParams();
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [state, dispatch] = useReducer(formReducer, initialState);
    const stateRef = useRef(state);
    const [hasNewEdits, setHasNewEdits] = useState(() => { return false });
    const [publishedFormStructure, setPublishedFormStructure] = useState(() => { return false })
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(state.title);
    const inputRef = useRef(null);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const settingsMenuRef = useRef(null);
    const [responses, setResponses] = useState([]);
    const [analytics, setAnalytics] = useState(false);
    const [isLoadingResponses, setIsLoadingResponses] = useState(false);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    useEffect(() => {
        const initializeForm = async () => {
            try {
                let formData = false
                setIsLoading(true)
                getFormDraft(formId).then((res) => {
                    if (res.data) {
                        if (res.data.form) {
                            if (res.data.form._id) {
                                formData = res.data.form
                            }
                            if (res.data.form.published) setPublishedFormStructure(res.data.form.published)
                        }
                    }
                    if (!formData) return navigate(`/o/${organizationId}/forms`)
                    dispatch({ type: 'SET_FORM_DATA', payload: formData })
                    setIsLoading(false)
                })
                .catch((err) => {
                    return navigate(`/o/${organizationId}/forms`)
                })
            } catch (error) {
                console.error('Error fetching form data:', error);
                navigate(`/o/${organizationId}/forms`)
            }
        };

        initializeForm();
    // eslint-disable-next-line
    }, [formId]);
    const checkForFormStructureChanges = () => {
        try {
            if (!publishedFormStructure) return setHasNewEdits(true)
            const structuresAreDifferent = publishedFormHasBasicDifferences(publishedFormStructure, state)
            setHasNewEdits(structuresAreDifferent)
        } catch (error) {
            return setHasNewEdits(true) // Assume they are different
        }
    }
    useEffect(() => {
        if (isLoading) return
        checkForFormStructureChanges()
    // eslint-disable-next-line
    }, [state])
    useEffect(() => {
        const fetchResponses = async () => {
            if (formId) {
                setIsLoadingResponses(true);
                try {
                    const res = await getFormResponses(formId);
                    if (res.data && res.data.responses) {
                        setResponses(res.data.responses);
                    }
                    if (res.data && res.data.analytics) {
                        setAnalytics(res.data.analytics)
                    }
                } catch (error) {
                    console.error('Error fetching form responses:', error);
                } finally {
                    setIsLoadingResponses(false);
                }
            }
        };

        fetchResponses();
    }, [formId]);
    const navItems = [
        { id: 'questions', icon: RiSurveyFill, title: 'Questions' },
        { id: 'design', icon: AiFillFormatPainter, title: 'Design' },
        { id: 'notifications', icon: MdMarkEmailUnread, title: 'Notifications' },
        { id: 'schedule', icon: FaCalendarCheck, title: 'Schedule' },
        { id: 'sharing', icon: FaShare, title: 'Sharing' },
        { id: 'responses', icon: MdMessage, title: 'Responses' },
        { id: 'analytics', icon: IoAnalyticsOutline, title: 'Analytics' },
    ];
    const saveUpdatesAfterDelay = () => {
        if (timerRef.current) clearTimeout(timerRef.current);
        
        changesPendingRef.current = true;
        
        timerRef.current = setTimeout(() => {
            if (changesPendingRef.current) {
                if (!formId) return
                dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving...' });
                checkForFormStructureChanges()
                updateFormDraft(formId, stateRef.current )
                .then((res) => {
                    changesPendingRef.current = false;
                    dispatch({ type: 'SET_SAVE_STATE', payload: 'Draft saved' });
                })
                .catch((err) => {
                    changesPendingRef.current = false;
                    dispatch({ type: 'SET_SAVE_STATE', payload: 'Error: draft not saved' });
                })
                
            }
        }, 2500);
    };
    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);
    const handleNavClick = (id) => {
        if (isValidNavValue(id)) {
            dispatch({ type: 'SET_SELECTED_NAV', payload: id });
            navigate(`/o/${organizationId}/forms/${formId}?v=${id}`, { replace: true });
        }
    };
    const handleFormTitleChange = (newTitle) => {
        dispatch({ type: 'SET_FORM_TITLE', payload: newTitle });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleQuestionsChange = (newQuestions) => {
        dispatch({ type: 'SET_QUESTIONS', payload: newQuestions });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleAddQuestion = (newQuestion, index) => {
        dispatch({ type: 'ADD_QUESTION', payload: { question: newQuestion, index } });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleUpdateQuestion = (updatedQuestion) => {
        dispatch({ type: 'UPDATE_QUESTION', payload: updatedQuestion });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleDeleteQuestion = (questionId) => {
        dispatch({ type: 'DELETE_QUESTION', payload: questionId });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleDesignUpdates = (designUpdates) => {
        dispatch({ type: 'UPDATE_DESIGN', payload: designUpdates });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleNotificationsUpdate = (updates) => {
        dispatch({ type: 'UPDATE_NOTIFICATIONS', payload: updates });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const handleScheduleUpdate = (updates) => {
        dispatch({ type: 'UPDATE_SCHEDULE', payload: updates });
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Saving changes...' });
        saveUpdatesAfterDelay();
    };
    const getQuestionCountText = () => {
        const count = state.structure.length;
        if (count === 0) return '';
        return count === 1 ? '1 question' : `${count} questions`;
    };
    const getResponseCountText = () => {
        const count = responses.length;
        return (
            <div className="response-count">
                <MdMessage className="response-icon" />
                <span>{count} {count === 1 ? 'response' : 'responses'}</span>
            </div>
        );
    };
    const handleTitleClick = () => {
        setIsEditing(true);
    };
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const handleTitleBlur = () => {
        setIsEditing(false);
        handleFormTitleChange(title || 'Untitled Form');
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleTitleBlur();
        }
    };
    useEffect(() => {
        if (isEditing) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);
    useEffect(() => {
        setTitle(state.title);
    }, [state.title]);
    const handleBackClick = () => {
        navigate(`/o/${organizationId}/forms`);
    };
    const handlePublish = () => {
        setShowPublishModal(true);
    };
    const handleConfirmPublish = () => {
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Publishing...' });
        updateFormDraft(formId, { ...stateRef.current, status: 'active' })
            .then((res) => {
                dispatch({ type: 'SET_FORM_DATA', payload: { status: 'active' } });
                dispatch({ type: 'SET_SAVE_STATE', payload: 'Published' });
                setShowPublishModal(false);
            })
            .catch((err) => {
                dispatch({ type: 'SET_SAVE_STATE', payload: 'Error publishing' });
                setShowPublishModal(false);
            });
    };
    const handleCancelPublish = () => {
        setShowPublishModal(false);
    };
    const handleUpdateForm = () => {
        // Implement update form logic
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Publishing changes...' });
        updateFormDraft(formId, { update_draft: true })
        .then((res) => {
            dispatch({ type: 'SET_SAVE_STATE', payload: 'Published' });
            setHasNewEdits(false)
        })
        .catch((err) => {
            dispatch({ type: 'SET_SAVE_STATE', payload: 'Error publishing' });
        })
    };
    const handleDiscardChanges = () => {
        if (!hasNewEdits) return
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Resetting form...' })
        setIsLoading(true)
        updateFormDraft(formId, { reset_draft: true })
        .then((res) => {
            let formData = state
            if (res.data.form._id) formData = res.data.form
            dispatch({ type: 'SET_FORM_DATA', payload: formData })
            dispatch({ type: 'SET_SAVE_STATE', payload: '' })
            setIsLoading(false)
        })
    };
    const openLiveForm = () => {
        const newWindow = window.open(`/f/${formId}`, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }
    const openPreview = () => {
        const newWindow = window.open(`/pr/${organizationId}/${formId}`, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }
    const handleUnpublishForm = () => {
        dispatch({ type: 'SET_SAVE_STATE', payload: 'Unpublishing...' });
        updateFormDraft(formId, { ...stateRef.current, status: 'draft' })
            .then((res) => {
                dispatch({ type: 'SET_FORM_DATA', payload: { status: 'draft' } });
                dispatch({ type: 'SET_SAVE_STATE', payload: 'Unpublished' });
            })
            .catch((err) => {
                console.error('Error unpublishing form:', err);
                dispatch({ type: 'SET_SAVE_STATE', payload: 'Error unpublishing' });
            });
    };
    const renderActionButton = () => {
        if (isLoading) return <div style={{width: "102px"}}></div>
        if (state.status === 'draft' && state.structure.length === 0) {
            return <button className="split-button-primary split-button-primary-disabled" style={{borderRadius: "4px"}}>No questions</button>
        }
        if (state.structure.length === 0) {
            return <button className="split-button-primary" onClick={handleDiscardChanges} style={{borderRadius: "4px"}}>Discard Changes</button>
        }
        if (state.status === 'draft' && state.structure.length > 0) {
            return (
                <button className="new-form-design-publish-button" onClick={handlePublish}>
                    <FaRocket className="button-icon" />
                    Publish Form
                </button>
            );
        } else if (!hasNewEdits) {
            return <button className="split-button-primary split-button-primary-disabled" style={{borderRadius: "4px"}}>No changes</button>
        } else {
            return (
                <SplitButton hasNewEdits={hasNewEdits} primaryAction={handleUpdateForm} primaryLabel="Publish Changes"options={[ { label: "Discard Changes", action: handleDiscardChanges }]}/>
            );
        }
    };
    const renderContent = () => {
        if (isLoading) {
            return <div style={{marginTop: "200px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}><Loader/>Loading form...</div>;
        }

        switch (state.selectedNav) {
            case 'questions':
                return (
                    <FormBuilderDesignCanvas
                        formTitle={state.title}
                        questions={state.structure}
                        onFormTitleChange={handleFormTitleChange}
                        onQuestionsChange={handleQuestionsChange}
                        onAddQuestion={handleAddQuestion}
                        onUpdateQuestion={handleUpdateQuestion}
                        openPreview={openPreview}
                        onDeleteQuestion={handleDeleteQuestion}
                    />
                );
            case 'design':
                return (
                    <FormBuilderDesignOptions
                        formState={state}
                        onDesignUpdate={handleDesignUpdates}
                        openPreview={openPreview}
                    />
                );
            case 'analytics':
                return (
                    <FormBuilderAnalytics
                        formState={state}
                        analytics={analytics}
                    />
                );
            case 'notifications':
                return (
                    <FormBuilderNotifications
                        notificationState={{
                            notification_emails: state.notification_emails,
                            notify_on_response: state.notify_on_response,
                            notify_on_schedule_end: state.notify_on_schedule_end,
                            notify_on_date: state.notify_on_date,
                            notification_date: state.notification_date,
                            notification_includes: state.notification_includes
                        }}
                        onNotificationsUpdate={handleNotificationsUpdate}
                        analytics={analytics}
                        formId={formId}
                    />
                );
            case 'schedule':
                return (
                    <FormBuilderSchedule
                        scheduleState={{
                            schedule_start: state.schedule_start,
                            schedule_end: state.schedule_end
                        }}
                        onScheduleUpdate={handleScheduleUpdate}
                    />
                );
            case 'responses':
                return (
                    <FormResponseView 
                        responses={responses} 
                        formName={state.title} 
                    />
                );
            case 'sharing':
                return (
                    <FormSharing 
                        formState={state} 
                        organizationId={organizationId} 
                        formId={formId} 
                        openPreview={openPreview} 
                        openLiveForm={openLiveForm} 
                    />
                );
            default:
                // If somehow we get an invalid nav value, redirect to questions
                handleNavClick('questions');
                return null;
        }
    };
    const handleSettingsClick = (e) => {
        e.stopPropagation();
        if (showSettingsMenu) {
            setShowSettingsMenu(false)
            return
        } else {
            setShowSettingsMenu(true)
            return
        }
    };
    const handleClickOutside = useCallback((event) => {
        if (settingsMenuRef.current && !settingsMenuRef.current.contains(event.target)) {
            setShowSettingsMenu(false);
        }
    }, []);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    const handleMenuItemClick = (action) => {
        setShowSettingsMenu(false);
        action();
    };
    const handleDeleteForm = () => {
        setShowDeleteModal(true);
    };
    const confirmDeleteForm = () => {
        // Placeholder function for deleting the form
        setIsLoading(true)
        updateFormDraft(formId, { status: 'deleted' })
        setShowDeleteModal(false);
        // Navigate back to forms list or appropriate page
        navigate(`/o/${organizationId}/forms`);
    };
    const renderSettingsMenu = () => {
        if (!showSettingsMenu) return null;
        return (
            <div className="settings-menu">
                {state.status === "active" && (
                    <button onClick={() => handleMenuItemClick(openLiveForm)}>View Live Form</button>
                )}
                {state.status === "draft" && (
                    <button onClick={() => handleMenuItemClick(openPreview)}>Preview Form</button>
                )}
                {state.status === "active" && hasNewEdits && (
                    <button onClick={() => handleMenuItemClick(openPreview)}>Preview Draft</button>
                )}
                {state.status === "active" && (
                    <button onClick={() => handleMenuItemClick(handleUnpublishForm)}>Unpublish Form</button>
                )}
                <button onClick={() => handleMenuItemClick(handleDeleteForm)}>Delete Form</button>
            </div>
        );
    };
    const isValidNavValue = (value) => {
        const validNavs = ['questions', 'design', 'analytics', 'notifications', 'schedule', 'responses', 'sharing'];
        return validNavs.includes(value);
    };
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const navValue = params.get('v');
        
        if (navValue && isValidNavValue(navValue)) {
            dispatch({ type: 'SET_SELECTED_NAV', payload: navValue });
        } else if (!params.has('v')) {
            // If no 'v' parameter exists, set URL to default
            navigate(`/o/${organizationId}/forms/${formId}?v=questions`, { replace: true });
        }
    }, [navigate, organizationId, formId]);
    return (
        <div className="new-form-container">
            <header className="new-form-design-header">
                <div className="new-form-design-header-left">
                    <div className="new-form-design-back">
                        <FaArrowLeft
                            className="new-form-design-back-icon"
                            onClick={handleBackClick}
                        />
                    </div>
                    <div className="new-form-design-status">
                        <span className={`new-form-design-status-value ${state.status === 'draft' ? 'draft' : 'published'}`}>
                            {state.status === "active" ? "Published" : state.status}
                        </span>
                        {getQuestionCountText() && (
                            <span className="new-form-design-question-count">
                                {getQuestionCountText()}
                            </span>
                        )}
                        {!isLoadingResponses && getResponseCountText()}
                    </div>
                </div>

                <div className="new-form-design-header-right">
                    <div className="new-form-design-save-state">
                        <span className="new-form-design-save-state-value">{state.saveState}</span>
                    </div>
                    {renderActionButton()}
                    {state.status === "active" &&
                    <div className="icon-button-container" onClick={openLiveForm}>
                        <span className="icon-button-live-indicator"></span>
                        <div className="icon-button">
                            <AiFillEye/>
                        </div>
                    </div>
                    }
                    <div className="icon-button-container" ref={settingsMenuRef}>
                        <div className="icon-button" onClick={handleSettingsClick}>
                            <IoSettingsSharp />
                        </div>
                        {renderSettingsMenu()}
                    </div>
                </div>
            </header>
            <div className="new-form-design-title-container">
                {isEditing ? (
                    <input
                        ref={inputRef}
                        type="text"
                        value={title}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        onKeyDown={handleKeyDown}
                        className="new-form-design-title-input"
                    />
                ) : (
                    <h1 onClick={handleTitleClick} className="new-form-design-title">
                        {title}
                    </h1>
                )}
            </div>
            <div className="new-form-content-wrapper">
                <nav className="new-form-nav">
                    {navItems.map((item) => (
                        <div key={item.id} className={`new-form-nav-item ${item.id === 'home' ? 'new-form-nav-home' : ''} ${state.selectedNav === item.id ? 'new-form-nav-selected' : ''}`}>
                            <div onClick={() => handleNavClick(item.id)} className="new-form-nav-link">
                                <item.icon className="new-form-nav-icon" />
                                <span className="new-form-nav-title">{item.title}</span>
                            </div>
                        </div>
                    ))}
                </nav>
                <main className="new-form-content">
                    {renderContent()}
                </main>
            </div>
            {showPublishModal && (
                <PublishConfirmationModal
                    onConfirm={handleConfirmPublish}
                    onCancel={handleCancelPublish}
                />
            )}
            {showDeleteModal && (
                <div className="modal-overlay">
                    <div className="modal-content" style={{backgroundColor: "var(--color-dark)"}}>
                        <h2>Delete Form</h2>
                        <p>Are you sure you want to delete this form? This action cannot be undone.</p>
                        <div className="modal-actions">
                            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
                            <button onClick={confirmDeleteForm}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}