import React, { useState } from 'react';
import '../../MeetingFinder.css';

export default function PollJoinForm({ formData, onSubmit, onBack, meetingInformation }) {
    console.log(meetingInformation)
    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        if (!email) return true; // Email is optional
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (formData.email && !validateEmail(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            onSubmit({
                name: formData.name.trim(),
                email: formData.email.trim()
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        onSubmit({
            ...formData,
            [name]: value
        }, true);
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    return (
        <div className="meeting-finder-form meeting-finder-form-join">
            <div className="meeting-finder-manager-header">
                <h1>{meetingInformation.eventName}</h1>
                <div className="meeting-finder-manager-meta">
                    <div className="meeting-finder-manager-details">
                        <span>{meetingInformation.duration} minutes</span>
                        <span>•</span>
                        <span>Organized by {meetingInformation.organizer.name}</span>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="meeting-finder-form-group">
                    <label>Your Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        className={errors.name ? 'error' : ''}
                    />
                    {errors.name && <div className="meeting-finder-error">{errors.name}</div>}
                </div>

                <div className="meeting-finder-form-group">
                    <label>Email Address (Optional)</label>
                    <p className="meeting-finder-helper-text">
                        Provide your email if you'd like to edit your response later
                    </p>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email (optional)"
                        className={errors.email ? 'error' : ''}
                    />
                    {errors.email && <div className="meeting-finder-error">{errors.email}</div>}
                </div>

                <div className="meeting-finder-form-buttons">
                    <button type="button" onClick={onBack} className="meeting-finder-button-secondary">
                        Back
                    </button>
                    <button type="submit" className="meeting-finder-button-primary">
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
} 