import React, { useState } from 'react'
import { IoClose } from "react-icons/io5";
import '../form/canvas/ModalQuestionManage.css'
import Loader from '../loaders/Loader'

export default function ModalOrgNewForm({ closeForm, createNewForm }) {
    const [formName, setFormName] = useState("")
    const [isCreating, setIsCreating] = useState(false)

    const handleCreateFormAction = () => {
        if (!formName || formName.trim() === "") return
        setIsCreating(true)
        createNewForm(formName)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault()
            handleCreateFormAction()
        }
    }

    return (
        <div className="modal-question-manage-overlay">
            <div className="modal-question-manage-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-question-manage-header">
                    <h3>Create new form</h3>
                    <button className="modal-question-manage-close modal-question-manage-close-create" onClick={closeForm}>
                        <IoClose />
                    </button>
                </div>

                {!isCreating &&
                <div className="modal-question-manage-body">
                    <div className="modal-question-manage-main-information-container">
                        <div className="modal-question-manage-main-information-section">
                            <label>Enter a form Name</label>
                            <input 
                                autoFocus 
                                type="text" 
                                className="modal-question-manage-input" 
                                placeholder="What was your favorite part of the event?" 
                                value={formName} 
                                onChange={(e) => setFormName(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    </div>
                </div>
                }
                {isCreating &&
                <div className="modal-question-manage-body">
                    <Loader />
                </div>
                }
                {!isCreating &&
                <div className="modal-question-manage-footer">
                    <button className="modal-question-manage-cancel" onClick={closeForm}>
                        Cancel
                    </button>
                    <button className="modal-question-manage-save" onClick={handleCreateFormAction}>
                        Create
                    </button>
                </div>
                }
            </div>
        </div>
    )
}
