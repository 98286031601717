import React, { useState } from 'react';
import { FaGripVertical, FaTimes } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SelectMultipleOptions = ({ options, onChange }) => {
    const [newOption, setNewOption] = useState('');

    const handleAddOption = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' || e.keyCode === 13) {
            e.preventDefault();
            const trimmedOption = newOption.trim();
            if (trimmedOption && !options.includes(trimmedOption)) {
                onChange([...options, trimmedOption]);
                setNewOption('');
            }
        }
    };

    const handleRemoveOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        onChange(newOptions);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(options);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        onChange(items);
    };

    return (
        <div>
            <label className="modal-question-manage-option-label" style={{paddingBottom: "0.5rem"}}>Add choices</label>
            {options.length > 0 &&
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="options">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="modal-question-manage-options-list">
                            {options.map((option, index) => (
                                <Draggable key={option} draggableId={option} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} className="multiple-choice-option-item">
                                            <span {...provided.dragHandleProps}>
                                                <FaGripVertical className="multiple-choice-option-handle" />
                                            </span>
                                            <span className="multiple-choice-option-text">{option}</span>
                                            <FaTimes
                                                className="multiple-choice-option-remove"
                                                onClick={() => handleRemoveOption(index)}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            }
            <input
                type="text"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                onKeyDown={handleAddOption}
                placeholder="Add new option and press Enter"
                className="modal-question-manage-option-input"
                style={{marginTop: "1rem"}}
            />
        </div>
    );
};

export default SelectMultipleOptions;