import React from 'react';
import './PublishConfirmationModal.css';

const PublishConfirmationModal = ({ onConfirm, onCancel }) => {
    return (
        <div className="publish-confirmation-overlay">
            <div className="publish-confirmation-modal">
                <h2>Publish Form</h2>
                <p>Are you sure you want to publish this form? Once published, it will be available for responses.</p>
                <div className="publish-confirmation-buttons">
                    <button className="publish-confirmation-cancel" onClick={onCancel}>Cancel</button>
                    <button className="publish-confirmation-confirm" onClick={onConfirm}>Publish</button>
                </div>
            </div>
        </div>
    );
};

export default PublishConfirmationModal;