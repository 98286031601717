import React from 'react';
import { FaStar, FaCircle, FaHeart, FaThumbsUp, FaFlag } from 'react-icons/fa';
import { FaRegStar, FaRegCircle, FaRegHeart, FaRegThumbsUp, FaRegFlag } from 'react-icons/fa';
import '../TheForm.css';

const symbolMap = {
    star: { filled: FaStar, empty: FaRegStar },
    circle: { filled: FaCircle, empty: FaRegCircle },
    heart: { filled: FaHeart, empty: FaRegHeart },
    thumbsUp: { filled: FaThumbsUp, empty: FaRegThumbsUp },
    flag: { filled: FaFlag, empty: FaRegFlag },
};

const RatingQuestion = ({ question, value, onChange, primaryColor }) => {
    // Default values if scale is undefined
    const defaultScale = { min: 1, max: 5, symbol: 'star' };
    const scale = question.scale || defaultScale;
    const symbols = symbolMap[scale.symbol] || symbolMap.star;

    // Ensure min and max are valid numbers
    const min = typeof scale.min === 'number' ? scale.min : defaultScale.min;
    const max = typeof scale.max === 'number' ? scale.max : defaultScale.max;

    return (
        <div className="the-form-rating">
            <div className="the-form-rating-icons">
                {[...Array(max - min + 1)].map((_, index) => {
                    const ratingValue = index + min;
                    const Icon = ratingValue <= value ? symbols.filled : symbols.empty;
                    return (
                        <div className="the-form-rating-icon-container" key={index}>
                            <Icon key={index} className={`the-form-rating-icon ${ratingValue <= value ? 'filled' : 'empty'}`} onClick={() => onChange(ratingValue)} style={{ color: primaryColor, cursor: 'pointer' }}/>
                            {index === 0 && scale.minLabel && <span className="the-form-rating-label min-label" style={{color: primaryColor}}>{scale.minLabel}</span>}
                            {index === (scale.max - 1) && scale.maxLabel && <span className="the-form-rating-label max-label" style={{color: primaryColor}}>{scale.maxLabel}</span>}
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default RatingQuestion;