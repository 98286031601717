import React from 'react';
import { FaWpforms, FaCog } from 'react-icons/fa';
import { MdPeopleAlt } from "react-icons/md";
import { useNavigate, useLocation } from 'react-router-dom';
import { useOrganization } from '../../context/OrganizationContext';

export default function OrgSidebarDesktop() {
    const navigate = useNavigate();
    const location = useLocation();
    const { organization } = useOrganization();

    const navItems = [
        // { icon: FaHome, label: 'Dashboard', path: '' },
        { icon: FaWpforms, label: 'Forms', path: 'forms' },
        { icon: MdPeopleAlt, label: 'Users', path: 'users' },
        { icon: FaCog, label: 'Account', path: 'settings' }
    ];

    const isActive = (path) => {
        const currentPath = location.pathname;
        const itemPath = `/o/${organization?._id}${path ? `/${path}` : ''}`;
        return currentPath === itemPath;
    };

    const handleNavigation = (path) => {
        if (organization) {
            navigate(`/o/${organization._id}${path ? `/${path}` : ''}`);
        }
    };

    return (
        <div className="org-sidebar-desktop">
            <div className="org-sidebar-desktop-display">
                <div className="org-sidebar-desktop-nav-list">
                    {navItems.map((item, index) => (
                        <div 
                            key={index}
                            className={`org-sidebar-desktop-nav-item ${isActive(item.path) ? 'active' : ''}`}
                            onClick={() => handleNavigation(item.path)}
                        >
                            <div className="org-sidebar-desktop-icon-wrapper">
                                <item.icon className="org-sidebar-desktop-icon" />
                            </div>
                            <span>{item.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
