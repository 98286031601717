import React from 'react'
import './OrgSidebar.css'
import OrgSidebarDesktop from './OrgSidebarDesktop'
import OrgSidebarMobile from './OrgSidebarMobile'

export default function OrganizationSidebar() {
    return (
        <div className="org-sidebar-container">
            <div className="org-sidebar-desktop-wrapper">
                <OrgSidebarDesktop />
            </div>
            <div className="org-sidebar-mobile-wrapper">
                <OrgSidebarMobile />
            </div>
        </div>
    )
}
