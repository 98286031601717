import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './SetupAccount.css'
import { trial_plans } from '../../config/TRIAL_PLANS'
import { GrRadial, GrRadialSelected } from "react-icons/gr";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { formatUSDCurrency, formatNumber } from '../../helpers/Helpers';
import Loader from '../loaders/Loader'
import { initializeOrganization } from '../../api';

export default function SetupAccount() {
    const { logout, organizations, loading } = useAuth()
    const navigate = useNavigate()
    const [page, setPage] = useState(() => { return 1 })
    const [isMonthly, setIsMonthly] = useState(() => { return true })
    const [selectedPlan, setSelectedPlan] = useState(() => { return "launch_starter" })
    const [firstName, setFirstName] = useState(() => { return "" })
    const [organizationName, setOrganizationName] = useState(() => { return "" })
    const [validPageOne, setValidPageOne] = useState(() => { return false })
    const handleNevermind = () => {
        logout()
        navigate("/")
    }
    const checkPageOneValidation = useCallback(() => {
        try {
            if (organizationName === "" || firstName === "") return setValidPageOne(false)
            if (organizationName.trim() === "") return setValidPageOne(false)
            if (firstName.trim() === "") return setValidPageOne(false)
            setValidPageOne(true)
        } catch (error) {
            setValidPageOne(false)
        }
    }, [organizationName, firstName])
    const handlePageOneButtonPressing = (event) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            if (validPageOne) return setPage(2)
        }
    }
    const submitOrganizationRequest = () => {
        setPage(3)
        initializeOrganization(firstName, organizationName, selectedPlan, !isMonthly)
        .then((res) => {
            if (!res.data) {
                // Something went wrong
                setPage(4)
                return
            }
            if (res.data.url) {
                window.location.href = res.data.url
            }
        })
        .catch((err) => { return setPage(4) })
    }
    useEffect(() => {
        checkPageOneValidation()
    }, [checkPageOneValidation])
    useEffect(() => {
        if (organizations.length > 0) setPage(2)
    }, [loading, organizations])
    return (
        <div className="setup-account">
            {loading && <Loader/>}
            {!loading &&
            <div className="setup-account-form-container">
                {page < 3 && <div className="setup-account-form-progress">{page}/2</div>}

                {/* PAGE 1: GENERAL INFORMATION */}
                {page === 1 && !loading &&
                <div>
                    <h2>Let's finish setting up your account</h2>
                    <p>Tell us more about you and your business</p>
                    <div className="setup-account-form-group">
                        <label htmlFor="email">First name</label>
                        <input type="text" onKeyDown={(e) => handlePageOneButtonPressing(e)} id="name" placeholder="Enter your name" autoFocus maxLength={30} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div className="setup-account-form-group">
                        <label htmlFor="email">Organization name</label>
                        <input type="text" onKeyDown={(e) => handlePageOneButtonPressing(e)} id="orgname" placeholder="Acme, Inc" maxLength={80} value={organizationName} onChange={(e) => setOrganizationName(e.target.value)}/>
                    </div>
                    <div className="setup-account-form-buttons">
                        {validPageOne && <button onClick={() => setPage(2)}>Continue</button>}
                        {!validPageOne && <button style={{backgroundColor: "var(--color-grey)"}}>Continue</button>}
                        <span onClick={handleNevermind}>Nevermind</span>
                    </div>
                </div>
                }

                {/* PAGE 2: TRIAL PLAN SELECTION */}
                {page === 2 && !loading &&
                <div>
                    <h2>How many responses do you want per month?</h2>
                    <p>Your subscription will begin with a 14-day free trial. Cancel at any time.</p>
                    <div className="billing-toggle-container">
                        <div className="billing-toggle">
                            <button className={`toggle-button ${isMonthly ? 'active' : ''}`} onClick={() => setIsMonthly(true)}>Monthly</button>
                            <button className={`toggle-button ${!isMonthly ? 'active' : ''}`} onClick={() => setIsMonthly(false)}>Annual <span style={isMonthly ? {color: "var(--color-grey)"} : {}}>(16% off)</span> </button>
                        </div>
                    </div>
                    <div className="setup-account-form-plans">
                        {trial_plans.map((plan) => (
                            <div className={`setup-account-form-plan ${selectedPlan === plan.tetherform_plan_ref && "selected"}`} key={plan.tetherform_plan_ref} onClick={() => setSelectedPlan(plan.tetherform_plan_ref)}>
                                <h4>
                                    <div className="setup-account-form-plan-selector">
                                        {selectedPlan === plan.tetherform_plan_ref ? <GrRadialSelected/> : <GrRadial /> }
                                    </div>
                                    {formatNumber(plan.monthly_responses)}
                                    <span>responses</span>
                                </h4>
                                <div className="setup-account-form-plan-info">
                                    <div style={{fontWeight: 600}}>{plan.display_name} plan</div>
                                    {isMonthly ?
                                    <div>
                                        <div className="setup-account-form-plan-info-costs">
                                            <span>{formatUSDCurrency(plan.monthly_price)} /mo</span>
                                            <span>Billed monthly</span>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="setup-account-form-plan-info-costs">
                                            <span>{formatUSDCurrency(plan.annual_price/12)} /mo</span>
                                            <span>{formatUSDCurrency(plan.annual_price)} billed annually </span>
                                        </div>
                                    </div>
                                    }
                                    
                                </div>
                            </div>
                        ))}
                    </div>

                    <div>
                        <h3>Included in every plan</h3>
                        <div className="setup-account-form-features">
                            <span><BsFillLightningChargeFill/>Unlimited forms</span>
                            <span><BsFillLightningChargeFill/>Advanced analytics</span>
                            <span><BsFillLightningChargeFill/>Automatic inbox delivery for up to 50 emails per form</span>
                        </div>
                    </div>

                    <div className="setup-account-form-buttons">
                        <button onClick={submitOrganizationRequest}>Launch Tetherform</button>
                        {organizations.length < 1 && <span onClick={() => setPage(1)}>Back</span>}
                    </div>
                </div>
                }

                {/* PAGE 3: THINKING */}
                {page === 3 &&
                <div style={{paddingTop: "32px"}}>
                    <Loader/>
                </div>
                }

                {/* PAGE 4: SOMETHING WENT WRONG */}
                {page === 4 &&
                <div>
                    <h2>Oh man...</h2>
                    <p>You aren't able to create an organization right now. Please reach out to our support team if you think this is an error</p>
                    <div style={{marginTop: "40px"}}>Support email: <a href="mailto:support@tetheros.com">support@tetheros.com</a></div>
                    <div className="setup-account-form-buttons">
                        <button onClick={handleNevermind}>Return</button>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    )
}
