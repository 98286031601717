import React from 'react';
import './FormBuilder.css';
import QuestionCanvas from './canvas/QuestionCanvas';

export default function FormBuilderDesignCanvas({ questions, openPreview, onQuestionsChange, onAddQuestion, onUpdateQuestion, onDeleteQuestion }) {
    return (
        <div className="new-form-design-container">
            <QuestionCanvas 
                questions={questions}
                onQuestionsChange={onQuestionsChange}
                onAddQuestion={onAddQuestion}
                onUpdateQuestion={onUpdateQuestion}
                onDeleteQuestion={onDeleteQuestion}
                openPreview={openPreview}
            />
        </div>
    );
}
