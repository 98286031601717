import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import Loader from '../loaders/Loader'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom';

export default function DashboardSplash() {
    // Determines what to do with the user based on their authentication status - loads, then redirects
    const [selectableOrganizations, setSelectableOrganizations] = useState(() => { return [] })
    const { loading, organizations, user, isActiveAccount } = useAuth();
    const navigate = useNavigate()
    const redirectUser = () => {
        if (loading) return
        if (selectableOrganizations.length > 0) return
        if (user && !organizations) return navigate('/account-setup')
        if (user && organizations.length === 0) return navigate('/account-setup')
        if (user && organizations.length === 0 && !isActiveAccount) return navigate('/account-setup')
        if (!isActiveAccount) return navigate('/account-setup')
        if (user && organizations.length === 1) {
            return navigate(`/o/${organizations[0]._id}/forms`)
        }
        setSelectableOrganizations(Array.from(organizations))
    }
    useEffect(() => {
        redirectUser()
    // eslint-disable-next-line
    }, [loading, user, organizations])
    return (
        <div className="dashboard-splash-container">
            {(loading || selectableOrganizations.length === 0) && <Loader />}
            {!loading && selectableOrganizations.length > 1 &&
            <div>
                <h2>Select an organization to manage</h2>
                <div>
                    Loading...
                </div>
            </div>
            }
        </div>
    )
}
