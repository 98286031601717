import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COMMON_TIMEZONES } from '../utils/timeUtils';
import '../../MeetingFinder.css';

const DURATION_OPTIONS = [
    { value: 15, label: '15 min' },
    { value: 30, label: '30 min' },
    { value: 60, label: '1 hr' }
];

export default function BasicInfoForm({ initialData, onSubmit, onBack }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: initialData.name || '',
        email: initialData.email || '',
        eventName: initialData.eventName || '',
        duration: initialData.duration || 30,
        timezone: initialData.timezone || 'America/Chicago'
    });
    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.email.trim()) newErrors.email = 'Email is required';
        else if (!validateEmail(formData.email)) newErrors.email = 'Invalid email format';
        if (!formData.eventName.trim()) newErrors.eventName = 'Event name is required';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('duration', formData.duration);
            navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
            onSubmit(formData);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleDurationChange = (duration) => {
        setFormData(prev => ({
            ...prev,
            duration: duration
        }));
    };

    return (
        <div className="meeting-finder-form">
            <form onSubmit={handleSubmit}>
                <div className="meeting-finder-form-group">
                    <label>Your Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        className={errors.name ? 'error' : ''}
                    />
                    {errors.name && <div className="meeting-finder-error">{errors.name}</div>}
                </div>

                <div className="meeting-finder-form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your email"
                        className={errors.email ? 'error' : ''}
                    />
                    {errors.email && <div className="meeting-finder-error">{errors.email}</div>}
                </div>

                <div className="meeting-finder-form-group">
                    <label>Event Name</label>
                    <input
                        type="text"
                        name="eventName"
                        value={formData.eventName}
                        onChange={handleChange}
                        placeholder="Enter event name"
                        className={errors.eventName ? 'error' : ''}
                    />
                    {errors.eventName && <div className="meeting-finder-error">{errors.eventName}</div>}
                </div>

                <div className="meeting-finder-form-row">
                    <div className="meeting-finder-form-group">
                        <label>Meeting Duration</label>
                        <div className="meeting-finder-duration-options">
                            {DURATION_OPTIONS.map(option => (
                                <button
                                    key={option.value}
                                    type="button"
                                    className={`meeting-finder-duration-option ${formData.duration === option.value ? 'selected' : ''}`}
                                    onClick={() => handleDurationChange(option.value)}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="meeting-finder-form-group">
                        <label>Time Zone</label>
                        <select
                            name="timezone"
                            value={formData.timezone}
                            onChange={handleChange}
                            className="meeting-finder-select"
                        >
                            {COMMON_TIMEZONES.map(tz => (
                                <option key={tz.value} value={tz.value}>
                                    {tz.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="meeting-finder-form-buttons">
                    <button type="button" onClick={onBack} className="meeting-finder-button-secondary">
                        Back
                    </button>
                    <button type="submit" className="meeting-finder-button-primary">
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
} 