import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import './home.css'
import Footer from '../footer/Footer'
import { useAuth } from '../../context/AuthContext'
import Pricing from './Pricing';
import FAQ from './FAQ';

export default function Home() {
    const navigate = useNavigate()
    const { isAuthenticated } = useAuth()
    const [isShowingTutorial, setIsShowingTutorial] = useState(() => { return false })

    if (isAuthenticated) {
        return <Navigate to="/welcome" replace />
    }

    return (
        <div className="home">

            <div className="home-responsive-hero-container">
                <div className="home-responsive-hero-primary">
                    <h1>Survey platform for<br/><span>professional communities</span></h1>
                    <p>Learn what your members really think with surveys that automatically generate insights.</p>
                    
                    <div className="home-primary-cta">
                        <div className="home-primary-cta-register" onClick={() => navigate('/register')}>Start your 14-day trial</div>
                    </div>
                </div>

                <div className="home-hero-video-container">
                    <div className="home-hero-video">
                        <video webkit-playsinline="true" playsInline autoPlay muted loop src="https://storage.googleapis.com/tetheros-public/tetherform_landing/2024%2010%2028%20Tetherform%20Landing%20Demo.mp4"></video>
                    </div>
                </div>
            </div>

            <div className="home-social-proof-container">
                <p>Trusted for feedback by leaders everywhere</p>
                <div className="home-social-proof-names">
                    <img src="https://storage.googleapis.com/tetheros-public/landing/tetheros_logo_blue.png" alt="Tetheros" />
                    <img src="https://storage.googleapis.com/tetheros-public/landing/logos/moonlite-logo-white.svg" alt="MoonLite" />
                    <img src="https://storage.googleapis.com/tetheros-public/landing/logos/comment-pilgrim-color-logo.png" alt="Comment Pilgrim" />
                    <img src="https://storage.googleapis.com/tetheros-public/landing/logos/armatage-candle-company-logo-icon-darl.png" alt="Armatage Candle Company" />
                </div>
            </div>

            <div className="home-section-landing" style={{marginTop: "100px"}}>
                <h2>From responses to insights, <span style={{color: "var(--color-brand)"}}>instantly</span></h2>
                <p className="home-section-landing-informational-text">Tetherform analyzes community feedback for insights <span style={{fontWeight: 800}}>automatically</span> to help you make better decisions in less time</p>
            </div>

            <div className="home-section-collect home-section-landing">
                <div style={{display: "flex", justifyContent: "center", marginBottom: "32px"}}>
                    <div className="loader-b"></div>
                </div>
                <h2>Create meaningful surveys</h2>
                <div className="home-section-landing-subtitle">To create a space for your community to be heard</div>
                <div className="home-section-collect-surveys">
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform_phone_white_1.png" alt="Tetherform Survey Light Mode" />
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform_phone_dark_1.png" alt="Tetherform Survey Dark Mode" />
                    <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform_phone_white_2.png" alt="Tetherform Survey Light Mode 2" />
                </div>
                <div style={{display: "flex", justifyContent: "center", marginTop: "100px"}}>
                    <div className="home-primary-cta-register" onClick={() => navigate('/register')}>Get started free</div>
                </div>
            </div>

            <div className="home-section-understand home-section-landing">
                <div style={{display: "flex", justifyContent: "center", marginBottom: "32px"}}>
                    <div className="loader-a"></div>
                </div>
                <h2>Understand your community better</h2>
                <div className="home-section-landing-subtitle">With insights & trends uniquely generated from their responses</div>

                <div className="home-section-understand-images">
                    <div className="home-section-understand-image">
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tf-data-4.png" alt="Fresh Tetherform Insights" style={{maxWidth: "unset"}} />
                    </div>
                    <div className="home-section-understand-image">
                        <div className="home-section-understand-image-commentary">
                            <h4>Deeper insights</h4>
                            <div>Tetherform reviews every response to help you connect the dots and efficiently make data-driven decisions.</div>
                        </div>
                        <img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tf-analytics-1.png" alt="Raw Tetherform Data" />
                    </div>

                </div>
            </div>

            <div className="home-section-understand home-section-landing">
                <div style={{display: "flex", justifyContent: "center", marginBottom: "32px"}}>
                    <div className="loader-c"></div>
                </div>
                <h2>Automate your process</h2>
                <div className="home-section-landing-subtitle">Skip the login and send reports directly to any inbox</div>

                <div className="home-section-understand-images">
                    <div className="home-section-understand-image" style={{display: "flex", justifyContent: "center"}}>
                        <img style={{padding: "12px", backgroundColor: "var(--color-extra-dark)", borderRadius: "12px", display: "flex", justifyContent: "center"}} src="https://storage.googleapis.com/tetheros-public/tetherform_landing/tetherform-notifications.png" alt="Custom notifications" />
                    </div>
                </div>

                {isShowingTutorial &&
                <div style={{width: "100%", maxWidth: "1000px", display: "flex", justifyContent: "center", marginLeft: "auto", marginRight: "auto", marginTop: "80px", padding: "20px", boxSizing: "border-box"}}>
                    <iframe width="1000px" height="600px" src="https://www.youtube-nocookie.com/embed/fPJx5SHh5nU?si=x6BA6TtNfmDgHUGi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                }

                <div className="home-cta-doublewide">
                    <div className="home-primary-cta-register" onClick={() => navigate('/register')}>Get started</div>
                    {!isShowingTutorial && <div className="home-primary-cta-register" onClick={() => setIsShowingTutorial(true)} style={{backgroundColor: "var(--color-grey)"}}>Watch a tutorial</div>}
                </div>
            </div>
            <Pricing/>
            <FAQ/>
            <div style={{fontSize: "1.2rem", marginTop: "80px"}}>Make better decisions for your organization</div>
            <Footer/>
        </div>
    )
}