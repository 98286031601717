import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getGroupPoll, addPollResponse } from '../../../api';
import PollJoinForm from './components/PollJoinForm';
import TimeSlotSelection from './components/TimeSlotSelection';
import JoinConfirmation from './components/JoinConfirmation';
import Loader from '../../loaders/Loader';
import '../MeetingFinder.css';

export default function MeetingFinderJoin() {
    const navigate = useNavigate();
    const { pollId } = useParams();
    const [searchParams] = useSearchParams();
    const [meetingInformation, setMeetingInformation] = useState(() => { return false })
    const [currentStep, setCurrentStep] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // Lift form data state up
    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });
    
    // Lift selected slots state up
    const [selectedSlots, setSelectedSlots] = useState([]);
    
    const [pollData, setPollData] = useState({
        timezone: 'America/Chicago',
        proposedTimes: []
    });

    // Fetch poll data and check for existing response
    useEffect(() => {
        const fetchPoll = async () => {
            try {
                const response = await getGroupPoll(pollId);
                console.log('RESPONSE')
                console.log(response)
                
                if (!response.data?.poll || response.data.poll.status !== 'active') {
                    console.error('Poll not found or not active');
                    navigate('/meeting-finder');
                    return;
                }

                if (response.data) {
                    if (response.data.poll) setMeetingInformation(response.data.poll)
                }

                const sauce = searchParams.get('sauce');
                let existingResponse = null;

                // If sauce exists, look for matching response
                if (sauce && response.data.poll.responses) {
                    existingResponse = response.data.poll.responses.find(
                        response => response.secret_sauce === sauce
                    );
                }

                // Initialize poll data
                setPollData(prev => ({
                    ...prev,
                    pollId: response.data.poll.pollId,
                    eventName: response.data.poll.eventName,
                    duration: response.data.poll.duration,
                    timezone: response.data.poll.timezone,
                    proposedTimes: response.data.poll.proposedTimes
                }));

                // If existing response found, initialize form data and selected slots
                if (existingResponse) {
                    setFormData({
                        name: existingResponse.name,
                        email: existingResponse.email
                    });
                    setSelectedSlots(existingResponse.selectedTimes);
                    setPollData(prev => ({
                        ...prev,
                        secret_sauce: existingResponse.secret_sauce
                    }));
                    setCurrentStep(2);
                }

            } catch (error) {
                console.error('Error fetching poll:', error);
                navigate('/meeting-finder');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPoll();
    }, [pollId, navigate, searchParams]);

    const handleUserInfoSubmit = (userInfo, isUpdate = false) => {
        setFormData(userInfo);
        // Only proceed to next step if this isn't just an update
        if (!isUpdate) {
            setCurrentStep(2);
        }
    };

    const handleTimeSelectionSubmit = async (timeData) => {
        try {
            setIsLoading(true);
            setError(null);

            // Prepare response payload
            const responsePayload = {
                name: formData.name,
                email: formData.email,
                selectedTimes: timeData.selectedTimes,
                ...(pollData.secret_sauce && { secret_sauce: pollData.secret_sauce })
            };

            // Submit response
            const response = await addPollResponse(pollData.pollId, responsePayload);
            
            if (!response.data?.poll) {
                throw new Error('Failed to submit response');
            }

            // Update poll data with response
            setPollData(prev => ({
                ...prev,
                response: response.data.poll
            }));

            setCurrentStep(3);
        } catch (err) {
            console.error('Error submitting response:', err);
            setError('Failed to submit your response. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        } else {
            navigate('/meeting-finder');
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div className="meeting-finder-error">
                <p>{error}</p>
                <button 
                    onClick={() => setError(null)}
                    className="meeting-finder-button-primary"
                >
                    Try Again
                </button>
            </div>
        );
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <PollJoinForm
                        meetingInformation={meetingInformation}
                        formData={formData}
                        onSubmit={handleUserInfoSubmit}
                        onBack={handleBack}
                    />
                );
            case 2:
                return (
                    <TimeSlotSelection
                        pollData={pollData}
                        selectedSlots={selectedSlots}
                        onSelectedSlotsChange={setSelectedSlots}
                        onSubmit={handleTimeSelectionSubmit}
                        onBack={handleBack}
                    />
                );
            case 3:
                return (
                    <JoinConfirmation
                        meetingInformation={meetingInformation}
                        pollData={{
                            ...pollData,
                            ...formData,
                            selectedTimes: selectedSlots
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="meeting-finder">
            <div className="meeting-finder-join">
                {currentStep < 3 && (
                    <div className="meeting-finder-progress">
                        <div className="meeting-finder-progress-steps">
                            {[1, 2].map(step => (
                                <div 
                                    key={step}
                                    className={`meeting-finder-progress-step ${step === currentStep ? 'active' : ''} ${step < currentStep ? 'completed' : ''}`}
                                >
                                    {step}
                                </div>
                            ))}
                        </div>
                        <div className="meeting-finder-progress-labels">
                            <span className={currentStep === 1 ? 'active' : ''}>Your Info</span>
                            <span className={currentStep === 2 ? 'active' : ''}>Select Times</span>
                        </div>
                    </div>
                )}
                {renderStep()}
            </div>
        </div>
    );
} 