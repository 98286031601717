import React from 'react';
import { MdPeopleAlt } from "react-icons/md";
import { FaWpforms, FaCog } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOrganization } from '../../context/OrganizationContext';

export default function OrgSidebarMobile() {
    const navigate = useNavigate();
    const location = useLocation();
    const { organization } = useOrganization();
    const navItems = [
        // { icon: FaHome, label: 'Dashboard', path: '' },
        { icon: FaWpforms, label: 'Forms', path: 'forms' },
        { icon: MdPeopleAlt, label: 'Users', path: 'users' },
        { icon: FaCog, label: 'Account', path: 'settings' }
    ];
    const isActive = (path) => {
        const currentPath = location.pathname;
        const itemPath = `/o/${organization?._id}${path ? `/${path}` : ''}`;
        return currentPath === itemPath;
    };
    const handleNavigation = (path) => {
        if (organization) {
            navigate(`/o/${organization._id}${path ? `/${path}` : ''}`);
        }
    };
    return (
        <div className="org-sidebar-mobile">
            <div className="org-sidebar-mobile-nav-list">
                {navItems.map((item, index) => (
                    <div 
                        key={index}
                        className={`org-sidebar-mobile-nav-item ${isActive(item.path) ? 'active' : ''}`}
                        onClick={() => handleNavigation(item.path)}
                    >
                        <div className="org-sidebar-mobile-icon-wrapper">
                            <item.icon className="org-sidebar-mobile-icon" />
                        </div>
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
