import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format, addDays, startOfDay } from 'date-fns';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './AvailabilitySelector.css';

const getTimeRange = (timeStr, duration = 30) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const startDate = new Date();
    startDate.setHours(hours, minutes, 0);
    
    const endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + duration);
    
    const startFormat = format(startDate, 'h:mm');
    const endFormat = format(endDate, 'h:mm a');
    
    return `${startFormat} - ${endFormat}`;
};

const TIME_SLOTS = Array.from({ length: ((20 - 5) * 2) + 1 }, (_, i) => {
    const totalMinutes = (5 * 60) + (i * 30); // Start at 5 AM, increment by 30 minutes
    const hour = Math.floor(totalMinutes / 60);
    const minute = totalMinutes % 60;
    const timeStr = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    
    return {
        timeStr,
        displayTime: timeStr
    };
});

const sortSlots = (slots) => {
    return [...slots].sort((a, b) => {
        const [dateA, timeA] = a.split('_');
        const [dateB, timeB] = b.split('_');
        
        // Create Date objects for comparison
        const dateTimeA = new Date(`${dateA}T${timeA}`);
        const dateTimeB = new Date(`${dateB}T${timeB}`);
        
        return dateTimeA - dateTimeB;
    });
};

export default function AvailabilitySelector({ initialData, onSubmit, onBack }) {
    const [selectedDate, setSelectedDate] = useState(startOfDay(new Date()));
    const [selectedSlots, setSelectedSlots] = useState(initialData.selectedTimes || []);
    const [visibleDays, setVisibleDays] = useState([]);
    const [duration, setDuration] = useState(30);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartPosition, setDragStartPosition] = useState(null);
    const calendarRef = useRef(null);

    // Debounced window resize handler
    const debouncedResize = useCallback(() => {
        let timeoutId = null;
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                setIsMobile(window.innerWidth < 768);
            }, 500);
        };
    }, []);

    useEffect(() => {
        const handleResize = debouncedResize();
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [debouncedResize]);

    const updateVisibleDays = useCallback((date) => {
        const daysToShow = isMobile ? 1 : 5;
        const days = Array.from({ length: daysToShow }, (_, i) => addDays(date, i));
        setVisibleDays(days);
    }, [isMobile]);

    useEffect(() => {
        updateVisibleDays(selectedDate);
    }, [selectedDate, isMobile, updateVisibleDays]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const durationParam = parseInt(params.get('duration'));
        if (durationParam && [15, 30, 60].includes(durationParam)) {
            setDuration(durationParam);
        }
    }, []);

    const handleDateChange = (direction) => {
        setSelectedDate(prevDate => {
            return direction === 'next' 
                ? addDays(prevDate, 1) 
                : addDays(prevDate, -1);
        });
    };

    const formatTimeSlot = (date, timeSlot) => {
        return {
            displayTime: timeSlot.displayTime,
            slotKey: `${format(date, 'yyyy-MM-dd')}_${timeSlot.timeStr}`
        };
    };

    // Handle mouse events for drag selection
    const handleMouseDown = (slotKey, e) => {
        if (isMobile || e.button !== 0) return; // Only handle left mouse button on desktop
        e.preventDefault();
        setIsMouseDown(true);
        setDragStartPosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = useCallback((e, isMouseDown, isMobile, dragStartPosition, setIsDragging) => {
        if (!isMouseDown || isMobile) return;
        
        // Check if mouse has moved enough to consider it a drag
        const moveThreshold = 5;
        const deltaX = Math.abs(e.clientX - dragStartPosition.x);
        const deltaY = Math.abs(e.clientY - dragStartPosition.y);
        
        if (deltaX > moveThreshold || deltaY > moveThreshold) {
            setIsDragging(true);
        }
    }, []);

    const handleMouseEnter = (slotKey) => {
        if (!isMouseDown || isMobile) return;
        if (isDragging && !selectedSlots.includes(slotKey)) {
            setSelectedSlots(prev => [...prev, slotKey]);
        }
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
        setIsDragging(false);
        setDragStartPosition(null);
    };

    // Handle regular clicks
    const handleClick = (slotKey, e) => {
        if (isMobile || !isDragging) {
            setSelectedSlots(prev => {
                if (prev.includes(slotKey)) {
                    return prev.filter(slot => slot !== slotKey);
                }
                return [...prev, slotKey];
            });
        }
    };

    // Add global mouse up and move handlers
    useEffect(() => {
        const handleGlobalMouseUp = () => {
            setIsMouseDown(false);
            setIsDragging(false);
            setDragStartPosition(null);
        };

        const handleGlobalMouseMove = (e) => {
            handleMouseMove(e, isMouseDown, isMobile, dragStartPosition, setIsDragging);
        };

        window.addEventListener('mouseup', handleGlobalMouseUp);
        window.addEventListener('mousemove', handleGlobalMouseMove);
        
        return () => {
            window.removeEventListener('mouseup', handleGlobalMouseUp);
            window.removeEventListener('mousemove', handleGlobalMouseMove);
        };
    }, [isMouseDown, isMobile, dragStartPosition, handleMouseMove]);

    const renderTimeSlots = (date) => {
        return TIME_SLOTS.map(timeSlot => {
            const { timeStr } = timeSlot;
            const { slotKey } = formatTimeSlot(date, timeSlot);
            return (
                <div
                    key={slotKey}
                    className={`availability-time-slot ${selectedSlots.includes(slotKey) ? 'selected' : ''}`}
                    onMouseDown={(e) => !isMobile && handleMouseDown(slotKey, e)}
                    onMouseEnter={() => !isMobile && handleMouseEnter(slotKey)}
                    onClick={(e) => handleClick(slotKey, e)}
                >
                    {getTimeRange(timeStr, duration)}
                </div>
            );
        });
    };

    const handleSubmit = () => {
        // Debug logging
        console.group('AvailabilitySelector - Submit Debug');
        console.log('Selected Times:', sortSlots(selectedSlots).map(slotKey => {
            const [dateStr, timeStr] = slotKey.split('_');
            const date = new Date(dateStr);
            const [hours, minutes] = timeStr.split(':');
            const time = new Date(date.setHours(parseInt(hours), parseInt(minutes)));
            return {
                slotKey,
                date: format(date, 'EEEE, MMMM d'),
                time: format(time, 'h:mm a'),
                rawDateTime: `${dateStr}T${timeStr}`
            };
        }));
        console.log('Duration:', duration, 'minutes');
        console.log('Initial Data:', initialData);
        console.log('Current Date Range:', {
            startDate: format(visibleDays[0], 'yyyy-MM-dd'),
            endDate: format(visibleDays[visibleDays.length - 1], 'yyyy-MM-dd')
        });
        console.groupEnd();

        onSubmit({
            selectedTimes: selectedSlots,
            duration
        });
    };

    return (
        <div className="availability-selector">
            <div 
                className="availability-calendar"
                ref={calendarRef}
                onMouseUp={handleMouseUp}
            >
                <div className="availability-calendar-header">
                    <button 
                        className="availability-date-nav prev"
                        onClick={() => handleDateChange('prev')}
                        aria-label="Previous day"
                    >
                        <IoChevronBack />
                    </button>
                    {visibleDays.map((date, index) => (
                        <div key={index} className="availability-calendar-day">
                            <div className="availability-day-name">
                                {format(date, 'EEEE')}
                            </div>
                            <div className="availability-day-date">
                                {format(date, 'MMMM d')}
                            </div>
                        </div>
                    ))}
                    <button 
                        className="availability-date-nav next"
                        onClick={() => handleDateChange('next')}
                        aria-label="Next day"
                    >
                        <IoChevronForward />
                    </button>
                </div>
                <div className="availability-calendar-grid">
                    {visibleDays.map((date, index) => (
                        <div key={index} className="availability-time-column">
                            {renderTimeSlots(date)}
                        </div>
                    ))}
                </div>
            </div>

            <div className="meeting-finder-form-buttons">
                <button 
                    type="button" 
                    onClick={onBack} 
                    className="meeting-finder-button-secondary"
                >
                    Back
                </button>
                <button 
                    onClick={handleSubmit}
                    className="meeting-finder-button-primary"
                    disabled={selectedSlots.length === 0}
                >
                    Next
                </button>
            </div>
        </div>
    );
} 