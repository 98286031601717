import React, { useState, useEffect } from 'react'
import contentfulClient from '../../helpers/ContentfulApi';
import './Blog.css'
import { useNavigate } from 'react-router-dom';
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../helpers/Helpers'
import Footer from '../footer/Footer';

export default function BlogLanding() {
    let navigate = useNavigate()
    const [posts, setPosts] = useState(() => { return []})
    const updatePosts = () => {
        try {
            contentfulClient.getEntries({
                content_type: "blogPost",
                order: "-sys.createdAt",
                limit: 10
            }).then((response) => {
                if (response.items.length > 0) {
                    setPosts(response.items.map(item => item.fields))
                }
            })
            
        } catch (error) {
            
        }
    }
    const updateMeta = () => {
        try {
            if (!posts) return
            if (posts.length === 0) return

            // JSON-LD Data
            const itemListElement = posts.map((post, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "url": `https://www.tetherform.com/blog/${post.urlSlug}`
            }));
            updateSchemaOrgJSONLD({
                "@context": "http://schema.org",
                "@type": "ItemList",
                "itemListElement": itemListElement
            })

            // Meta
            let title = "Blog | Tetherform"
            let metaTags = [
                { name: 'description', content: 'Automated survey insights' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'Automated survey insights' },
                { name: 'og:url', content: 'https://tetherform.com/blog' },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
                { name: 'twitter:description', content: 'Automated survey insights' },
            ]
            updateHeadTags(title, metaTags)

        } catch (error) {
            return
        }
    }
    useEffect(() => {
        updatePosts()
        updateHeadTags("Blog | Tetherform")
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [posts])
    return (
        <div className="blog">
            <div className="blog-landing-heading">
                <div className="blog-landing-heading-inner">
                    <div className="blog-landing-heading-badge">Blog</div>
                    <h1>Guides for Building<br/>Strong Communities</h1>
                    <p>For members and leaders in high-value organizations</p>
                </div>
            </div>
            <div className="blog-landing-list">
                {posts.map((post) => (
                    <a 
                        key={post.urlSlug} 
                        className="blog-landing-list-content" 
                        href={`/blog/${post.urlSlug}`}
                        onClick={(e) => {
                            // Prevent default only for left clicks without modifier keys
                            if (!e.ctrlKey && !e.metaKey && e.button !== 1) {
                                e.preventDefault();
                                navigate(`/blog/${post.urlSlug}`);
                            }
                        }}
                    >
                        <div className="blog-landing-list-content-titles">
                            <h2>{post.blogTitle}</h2>
                            <div className="blog-landing-list-content-tagline">{post.tagline}</div>
                            <div className="blog-landing-list-content-categories">
                                {post.contentCategories.map((categoryName) => (
                                    <span key={categoryName}>{categoryName}</span>
                                ))}
                            </div>
                        </div>
                        {post.heroImage &&
                        <div className="blog-landing-list-content-image">
                            <img src={post.heroImage.fields.file.url} alt={post.heroImage.fields.description} />
                        </div>
                        }
                    </a>
                ))}
            </div>
            <Footer/>
        </div>
    )
}
