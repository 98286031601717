import React from 'react'
import Pricing from './Pricing'
import FAQ from './FAQ'
import Footer from '../footer/Footer'
import './home.css'
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../helpers/Helpers'

export default function PricingPage() {
    const updateMeta = () => {
        let title = "Pricing | Tetherform"
        const url = "https://tetherform.com/plans"
        const description = ""
        const imageUrl = 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg'
        let metaTags = [
            { name: 'description', content: description},
            { name: 'author', content: 'Tetherform' },
            { name: 'og:title', content: title },
            { name: 'og:description', content: description},
            { name: 'og:type', content: 'website' },
            { name: 'og:image', content: imageUrl },
            { name: 'og:url', content: url },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: title },
            { name: 'twitter:site', content: "@tetheros" },
            { name: 'twitter:url', content: url },
            { name: 'twitter:description', content: description},
            { name: 'twitter:image', content: imageUrl },
            { name: 'twitter:image:alt', content: 'No alt text provided' }
        ]
        const structuredData = {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "headline": "Pricing | Tetherform",
            "image": imageUrl,
            "publisher": {
              "@type": "Organization",
              "name": "Tetherform",
              "logo": {
                "@type": "ImageObject",
                "url": "https://storage.googleapis.com/tetheros-public/tetherform_landing/logo-tetherform-small.png"
              }
            },
            "datePublished": new Date().toISOString(),
            "dateModified": new Date().toISOString(),
            "description": description,
            "about": "Pricing plans for the Tetherform application"
        };
        updateHeadTags(title, metaTags)
        updateSchemaOrgJSONLD(structuredData)
    }
    updateMeta()
    return (
        <div className="pricing-page">
            <div className="pricing-page-content">
                <Pricing/>
                <FAQ/>
            </div>
            <Footer/>
        </div>
    )
}
