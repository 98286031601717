exports.formatUSDCurrency = (amount, min=2, max=2) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: min,
      maximumFractionDigits: max
    }).format(amount);
}

exports.formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
}

exports.isNonViewingRole = (roleName) => {
  try {
    const NONVIEWABLE_ROLENAMES = ['editor', 'admin']
    if (NONVIEWABLE_ROLENAMES.includes(roleName)) return true
    return false
  } catch (error) {
    return false
  }
}

exports.publishedFormHasBasicDifferences = (existing, incoming) => {
  let hasDifferences = false

  // Check question structure
  try {
    let existingStructure = existing.structure
    let incomingStructure = incoming.structure
    // Check if both are arrays
    if (!Array.isArray(existingStructure) || !Array.isArray(incomingStructure)) {
      throw new Error('Both structures must be arrays');
    }

    // Compare lengths
    if (existingStructure.length !== incomingStructure.length) {
      return true; // Structures are different
    }

    // Compare each item
    for (let i = 0; i < existingStructure.length; i++) {
      const existingInformation = `${existingStructure[i].id}${existingStructure[i].type}${existingStructure[i].question}`
      const draftInformation = `${incomingStructure[i].id}${incomingStructure[i].type}${incomingStructure[i].question}`
      if (existingInformation !== draftInformation) {
        hasDifferences = true
        break
      }
    }
    if (hasDifferences) return true
  } catch (error) {
    return true
  }

  // Check design choices
  try {
    if (existing.design_primary_color !== incoming.design_primary_color) hasDifferences = true
    if (existing.design_dark_mode !== incoming.design_dark_mode) hasDifferences = true
    if (existing.form_display_name !== incoming.form_display_name) hasDifferences = true
    if (existing.introduction_message !== incoming.introduction_message) hasDifferences = true
    return hasDifferences
  } catch (error) {
    return true
  }
}

exports.getContrastColor = (hexColor) => {
  // Remove the hash if it's there
  hexColor = hexColor.replace('#', '');

  // Convert to RGB
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black for bright colors, white for dark colors
  return luminance > 0.5 ? 'var(--color-dark)' : 'var(--color-white-pure)';
};

exports.updateCanonicalTag = (canonicalUrl) => {
  try {
      // Remove all existing <link rel="canonical"> tags
      const existingCanonicalLinks = document.querySelectorAll('link[rel="canonical"]');
      existingCanonicalLinks.forEach(link => link.parentNode.removeChild(link));

      // Create and append a new <link rel="canonical"> tag
      const canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
  } catch (error) {
      console.error("Error updating canonical tag:", error);
  }
};

exports.updateHeadTags = (title, metaTags) => {
  try {
      if (title) document.title = title;

      // Default to an empty array if metaTags is undefined
      const providedMetaTags = metaTags || [];

      // Only proceed if there are new meta tags to apply
      if (providedMetaTags.length === 0) {
          const keepMetaTags = ['charset', 'viewport', 'X-UA-Compatible', 'theme-color'];
          const existingMetaTags = document.querySelectorAll('meta');

          existingMetaTags.forEach(meta => {
              const name = meta.getAttribute('name') || meta.getAttribute('http-equiv') || meta.getAttribute('property');
              if (!keepMetaTags.includes(name)) meta.parentNode.removeChild(meta);
          });

          this.removeSchemaOrgJSONLD();
          return;
      }

      const head = document.getElementsByTagName('head')[0];

      // Loop over the provided meta tags to either update or create each one
      providedMetaTags.forEach(tag => {
          // Remove existing meta tag if it exists
          const existingTag = document.querySelector(`meta[name="${tag.name}"]`) || 
                             document.querySelector(`meta[property="${tag.name}"]`);
          if (existingTag) {
              existingTag.parentNode.removeChild(existingTag);
          }

          // Create new meta tag
          const metaElement = document.createElement('meta');
          if (tag.name.startsWith('og:') || tag.name.startsWith('twitter:')) {
              metaElement.setAttribute('property', tag.name); // For Open Graph/Twitter tags
          } else {
              metaElement.setAttribute('name', tag.name); // For other meta tags
          }
          metaElement.setAttribute('content', tag.content);
          head.appendChild(metaElement);
      });
  } catch (error) {
      console.error("Error updating head tags:", error);
      return;
  }
};

exports.updateSchemaOrgJSONLD = (data) => {
  try {
      const scriptId = 'schema-org-json-ld'
      let script = document.getElementById(scriptId);
      if (!script) {
        script = document.createElement('script');
        script.type = 'application/ld+json';
        script.id = scriptId;
        document.getElementsByTagName('head')[0].appendChild(script);
      }
      script.textContent = JSON.stringify(data);
  } catch (error) {
      return
  }
}

exports.removeSchemaOrgJSONLD = () => {
  try {
      const scriptId = 'schema-org-json-ld'
      const script = document.getElementById(scriptId)
      if (script) script.parentNode.removeChild(script)
  } catch (error) {
      return
  }

}