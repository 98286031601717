import React from 'react'
import '../QuestionCanvas.css'
import '../ModalQuestionManage.css'
import { FaPlus } from 'react-icons/fa'

export default function AddQuestionButton({ onClick }) {
    return (
        <div className="question-canvas-add-question" onClick={onClick}>
            <div className="question-canvas-add-icon-container">
                <FaPlus className="question-canvas-add-icon" />
            </div>
            <span className="question-canvas-add-text">Add question</span>
        </div>
    );
} 