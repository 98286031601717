import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getGroupPollById, sendCalendarLinks } from '../../../api';
import { FaCheck, FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdOutlineMailOutline } from "react-icons/md";
import Loader from '../../loaders/Loader';
import '../MeetingFinder.css';

// TimeSlotBox component
const TimeSlotBox = ({ timeSlot, responses, duration, timezone, onSelectTime }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSending, setIsSending] = useState(false);

    // Calculate available and unavailable respondents
    const availableRespondents = responses.filter(r => r.selectedTimes.includes(timeSlot));
    const unavailableRespondents = responses.filter(r => !r.selectedTimes.includes(timeSlot));

    // Format the time slot display
    const formatTimeSlot = () => {
        const startTime = new Date(timeSlot);
        const endTime = new Date(startTime.getTime() + duration * 60000);
        
        return {
            date: startTime.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric'
            }),
            time: `${startTime.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit'
            })} - ${endTime.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit'
            })} ${timezone}`
        };
    };

    const { date, time } = formatTimeSlot();

    const handleSelectTime = async (e) => {
        e.stopPropagation(); // Prevent expansion toggle
        setIsSending(true);
        await onSelectTime(timeSlot);
        setIsSending(false);
    };

    return (
        <div className="meeting-finder-time-slot-box">
            <div 
                className="meeting-finder-time-slot-header"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="meeting-finder-time-slot-info">
                    <div className="meeting-finder-time-slot-date">{date}</div>
                    <div className="meeting-finder-time-slot-time">{time}</div>
                </div>
                <div className="meeting-finder-time-slot-summary">
                    {responses.length === 0 ? (
                        <span>No responses yet</span>
                    ) : (
                        <span className="meeting-finder-slot-info-symbol-summary">
                            {availableRespondents.map((_, index) => (
                                <FaCheck className="response-icon available" key={index} />
                            ))}
                            {unavailableRespondents.map((_, index) => (
                                <FaTimes className="response-icon unavailable"  key={index} />
                            ))}
                        </span>
                    )}
                    {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                </div>
            </div>

            <div className={`meeting-finder-time-slot-drawer ${isExpanded ? 'expanded' : ''}`}>
                <div className="meeting-finder-response-lists">
                    <div className="meeting-finder-response-column available">
                        <h4>Available ({availableRespondents.length})</h4>
                        {availableRespondents.length === 0 ? (
                            <div className="meeting-finder-no-responses">No available responses</div>
                        ) : (
                            <ul>
                                {availableRespondents.map((respondent, index) => (
                                    <li key={index}>
                                        <FaCheck className="response-icon available" />
                                        {respondent.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="meeting-finder-response-column unavailable">
                        <h4>Unavailable ({unavailableRespondents.length})</h4>
                        {unavailableRespondents.length === 0 ? (
                            <div className="meeting-finder-no-responses">No unavailable responses</div>
                        ) : (
                            <ul>
                                {unavailableRespondents.map((respondent, index) => (
                                    <li key={index}>
                                        <FaTimes className="response-icon unavailable" />
                                        {respondent.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className="meeting-finder-time-slot-actions">
                    <button 
                        onClick={handleSelectTime}
                        className="meeting-finder-button-primary meeting-finder-email-details-button-primary"
                        disabled={isSending}
                    >
                        <MdOutlineMailOutline style={{fontSize: "1rem"}} />
                        {isSending ? 'Sending...' : 'Send time slot to email'}
                    </button>
                </div>
            </div>
        </div>
    );
};

// Main component
export default function MeetingFinderManager() {
    const navigate = useNavigate();
    const { pollId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [poll, setPoll] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPoll = async () => {
            try {
                const response = await getGroupPollById(pollId);
                
                if (!response.data?.poll) {
                    navigate('/meeting-finder');
                    return;
                }

                setPoll(response.data.poll);
            } catch (error) {
                console.error('Error fetching poll:', error);
                navigate('/meeting-finder');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPoll();
    }, [pollId, navigate]);

    const handleSelectTime = async (timeSlot) => {
        try {
            setError(null);
            const response = await sendCalendarLinks(pollId, timeSlot);
            if (response.data?.success) {
                setSelectedTime(timeSlot);
            } else {
                setError('Failed to send calendar links. Please try again.');
            }
        } catch (err) {
            console.error('Error sending calendar links:', err);
            setError('An error occurred. Please try again.');
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!poll) {
        return null;
    }

    const PollHeader = () => (
        <div className="meeting-finder-manager-header">
            <h1>{poll.eventName}</h1>
            <div className="meeting-finder-manager-meta">
                <div className="meeting-finder-manager-organizer">
                    Organized by {poll.organizer.name} ({poll.organizer.email})
                </div>
                <div className="meeting-finder-manager-details">
                    <span>{poll.duration} minutes</span>
                    <span>•</span>
                    <span>{poll.timezone}</span>
                    <span>•</span>
                    <span>{poll.responses.length} {poll.responses.length === 1 ? 'response' : 'responses'}</span>
                </div>
            </div>
        </div>
    );

    if (selectedTime) {
        return (
            <div className="meeting-finder-manager">
                <PollHeader />
                <div className="meeting-finder-confirmation">
                    <div className="meeting-finder-confirmation-message">
                        <h2>Calendar Links Sent!</h2>
                        <p>Check your email for instructions on adding this event to your calendar.</p>
                        <p>You can now notify your participants of the selected time.</p>
                        <button 
                            onClick={() => setSelectedTime(null)}
                            className="meeting-finder-button-secondary"
                        >
                            Back to Responses
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    // Sort time slots by available responses count and then chronologically
    const sortedTimeSlots = [...poll.proposedTimes].sort((a, b) => {
        // Count available responses for each time slot
        const aAvailable = poll.responses.filter(r => r.selectedTimes.includes(a)).length;
        const bAvailable = poll.responses.filter(r => r.selectedTimes.includes(b)).length;

        // First sort by number of available responses (descending)
        if (bAvailable !== aAvailable) {
            return bAvailable - aAvailable;
        }

        // If same number of responses, sort chronologically
        return new Date(a) - new Date(b);
    });

    return (
        <div className="meeting-finder-manager">
            <PollHeader />
            
            {error && (
                <div className="meeting-finder-error-message">
                    {error}
                </div>
            )}

            <div className="meeting-finder-manager-responses">
                {sortedTimeSlots.map(timeSlot => (
                    <TimeSlotBox
                        key={timeSlot}
                        timeSlot={timeSlot}
                        responses={poll.responses}
                        duration={poll.duration}
                        timezone={poll.timezone}
                        onSelectTime={handleSelectTime}
                    />
                ))}
            </div>
        </div>
    );
}
