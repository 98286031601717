import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, TimeScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, eachDayOfInterval, startOfDay, endOfDay } from 'date-fns';
import './FormBuilderAnalytics.css';
import './FormBuilderOptions.css';
ChartJS.register(TimeScale, LinearScale, BarElement, Title, Tooltip, Legend);
const FormBuilderAnalytics = ({ formState, analytics }) => {
    const [view, setView] = useState(() => { return "insights"})
    const [insights, setInsights] = useState(() => { return []})
    const [recommendations, setRecommendations] = useState(() => { return []})
    const initRecommendationsAndInsights = () => {
        try {
            if (!analytics) return
            if (analytics.key_findings) setInsights(analytics.key_findings)
            if (analytics.form_insights) {
                if (analytics.form_insights.length > 0) setInsights(analytics.form_insights)
            }
            if (analytics.form_recommendations) setRecommendations(analytics.form_recommendations)
        } catch (error) {
            return false
        }
    }
    useEffect(() => {
        initRecommendationsAndInsights()
    // eslint-disable-next-line
    }, [analytics])
    if (!analytics) {
        return (
            <div className="form-builder-analytics-container">
                <div className="form-builder-design-options-header-row">
                    <h2>Analytics</h2>
                </div>
                <p className="form-builder-analytics-message">Analytics are not ready yet. Come back later.</p>
            </div>
        );
    }
    // Create a map of dates to response counts
    const responseCounts = analytics.response_timestamps.reduce((acc, item) => {
        const date = startOfDay(new Date(item.date)).toISOString();
        acc[date] = (acc[date] || 0) + item.count;
        return acc;
    }, {});
    // Get the date range
    const dates = analytics.response_timestamps.map(item => new Date(item.date));
    const minDate = startOfDay(Math.min(...dates));
    const maxDate = endOfDay(Math.max(...dates));
    // Generate all dates in the range
    const allDates = eachDayOfInterval({ start: minDate, end: maxDate });
    // Create the chart data with all dates, including zeros for missing data
    const chartData = {
        labels: allDates,
        datasets: [
            {
                label: 'Responses',
                data: allDates.map(date => ({
                    x: date,
                    y: responseCounts[startOfDay(date).toISOString()] || 0
                })),
                backgroundColor: '#309C83',
                borderColor: '#309C83',
                borderWidth: 1,
            },
        ],
    };
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM d'
                    }
                },
                title: {
                    display: true,
                    text: 'Date',
                    color: 'rgba(255, 255, 255, 0.7)'
                },
                grid: {
                    display: false
                },
                ticks: {
                    source: 'data',
                    autoSkip: false,
                    maxRotation: 0,
                    color: 'rgba(255, 255, 255, 0.7)',
                    align: 'center',
                    callback: function(value, index, values) {
                        return format(new Date(value), 'MMM d');
                    }
                },
                offset: false,
                alignment: 'center'
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Responses',
                    color: 'rgba(255, 255, 255, 0.7)'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.1)'
                },
                ticks: {
                    precision: 0,
                    color: 'rgba(255, 255, 255, 0.7)'
                },
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function(context) {
                        return format(new Date(context[0].parsed.x), 'MMMM d, yyyy');
                    }
                }
            },
            datalabels: {
                display: false
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        barPercentage: 1,
        categoryPercentage: 0.8,
    };
    const uniqueDays = new Set(analytics.response_timestamps.map(item => item.date.split('T')[0])).size;
    const showResponseChart = uniqueDays > 3;
    return (
        <div className="form-builder-analytics-container">
            <div className="form-builder-design-options-header-row">
                <h2>Analytics</h2>
                <div className="form-builder-analytics-header-metrics">
                    <div className="form-builder-analytics-metric">
                        <span className="form-builder-analytics-metric-number">{analytics.response_total}</span>
                        <span className="form-builder-analytics-metric-label">Responses</span>
                    </div>
                    {analytics.sentiment_score &&
                    <div className="form-builder-analytics-metric">
                        <span className="form-builder-analytics-metric-number">{analytics.sentiment_score}%</span>
                        <span className="form-builder-analytics-metric-label">Positivity</span>
                    </div>
                    }
                </div>
            </div>
            <div className="form-builder-analytics-tabs">
                <div className={`form-builder-analytics-tab ${view === "insights" ? "selected" : ""}`} onClick={() => setView("insights")}>Insights</div>
                {recommendations.length > 0 && <div className={`form-builder-analytics-tab ${view === "recommendations" ? "selected" : ""}`} onClick={() => setView("recommendations")}>Recommendations</div>}
            </div>
            {view === "insights" &&
            <div>
                <div className="form-builder-analytics-key-findings">
                    <div className="form-builder-analytics-box">
                        <div className="form-builder-analytics-findings-list">
                            {insights.map((finding, index) => (
                                <div key={index} className="form-builder-analytics-finding-item">
                                    <div className="form-builder-analytics-finding-headline">{finding.insight_headline}</div>
                                    <div className="form-builder-analytics-finding-summary">{finding.insight_summary}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {showResponseChart && (
                    <div className="form-builder-analytics-chart-row">
                        <div className="form-builder-analytics-box form-builder-analytics-chart-box">
                            <div className="form-builder-analytics-box-header">
                                <h3>Response Rate</h3>
                            </div>
                            <div className="form-builder-analytics-chart">
                                <Bar data={chartData} options={chartOptions} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            }
            {view === "recommendations" && recommendations.length > 0 &&
            <div>
                <div className="form-builder-analytics-key-findings">
                    <div className="form-builder-analytics-box">
                        <div className="form-builder-analytics-findings-list">
                            {recommendations.map((finding, index) => (
                                <div key={index} className="form-builder-analytics-finding-item">
                                    <div className="form-builder-analytics-finding-headline">{finding.recommendation_title}</div>
                                    <div className="form-builder-analytics-finding-summary">{finding.recommendation_description}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className="form-builder-analytics-padding"></div>
        </div>
    );
};
export default FormBuilderAnalytics;
