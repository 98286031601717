import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getOrganizationDetails } from '../api';

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [organizationId, setOrganizationId] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [role, setRole] = useState(false);
  const [forms, setForms] = useState([]);
  const [organizationIsLoading, setOrganizationIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const extractOrgIdFromPath = () => {
      const pathParts = location.pathname.split('/');
      const orgIndex = pathParts.indexOf('o');
      return orgIndex !== -1 && orgIndex < pathParts.length - 1 ? pathParts[orgIndex + 1] : null;
    };
    const urlOrgId = extractOrgIdFromPath();
    if (isAuthenticated && user && urlOrgId) {
      setOrganizationId(urlOrgId);
      fetchOrganizationDetails(urlOrgId);
    } else {
      setOrganizationIsLoading(false);
    }
  // eslint-disable-next-line
  }, [isAuthenticated, user, location.pathname]);

  const fetchOrganizationDetails = async (orgId) => {
    setOrganizationIsLoading(true);
    setError(null);
    try {
      const response = await getOrganizationDetails(orgId);
      if (response.data) {
        setOrganization(response.data.organization);
        setRole(response.data.role)
        setForms(response.data.forms)
      }
    } catch (err) {
      console.error("Error fetching organization details");
      setError("Failed to fetch organization details");
      navigate("/")
    } finally {
      setOrganizationIsLoading(false);
    }
  };

  const switchOrganization = async (newOrgId) => {
    setRole(false)
    setForms([])
    setOrganizationId(newOrgId);
    await fetchOrganizationDetails(newOrgId);
    navigate(`/o/${newOrgId}`);
  };

  return (
    <OrganizationContext.Provider value={{ 
      organizationId, 
      organization, 
      organizationIsLoading,
      role,
      forms,
      error, 
      switchOrganization,
      fetchOrganizationDetails
    }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export function useOrganization() {
  return useContext(OrganizationContext);
}
