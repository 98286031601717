import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGroupPoll } from '../../../api';
import { BsCalendarPlus } from "react-icons/bs";
import { IoAnalytics, IoTime, IoMailUnread } from "react-icons/io5";
import Loader from '../../loaders/Loader';
import Footer from '../../footer/Footer';
import '../MeetingFinder.css';
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../../helpers/Helpers';

export default function MeetingFinderLanding() {
    const navigate = useNavigate();
    const [pollId, setPollId] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handlePollIdSubmit = async (e) => {
        e.preventDefault();
        if (!pollId.trim()) {
            setError('Please enter a poll ID');
            return;
        }

        setError('');
        setIsLoading(true);

        try {
            const response = await getGroupPoll(pollId.trim());
            
            if (!response.data?.poll) {
                setError('Poll not found. Please check the ID and try again.');
                return;
            }

            if (response.data.poll.status !== 'active') {
                setError('This poll is no longer active.');
                return;
            }

            navigate(`/meeting-finder/polls/${response.data.poll.pollId}`);
        } catch (err) {
            console.error('Error fetching poll:', err);
            setError('Unable to find poll. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };
    const updateMeta = () => {
        if (isLoading) return
        let title = "Meeting Finder | Tetherform"
        let metaTags = [
            { name: 'description', content: 'Find the perfect time to meet with your team.' },
            { name: 'author', content: 'Tetherform' },
            { name: 'og:title', content: title },
            { name: 'og:description', content: 'Find the perfect time to meet with your team.' },
            { name: 'og:type', content: 'article' },
            { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
            { name: 'og:url', content: `https://www.tetherform.com/meeting-finder` },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: title },
            { name: 'twitter:site', content: "@tetheros" },
            { name: 'twitter:url', content: `https://www.tetherform.com/meeting-finder` },
            { name: 'twitter:description', content: 'Find the perfect time to meet with your team.' },
            { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
            { name: 'twitter:image:alt', content: 'No alt text provided' }
        ]
        const structuredData = {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "headline": "Meeting Finder",
            "image": 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg',
            "author": {
              "@type": "Person",
              "name": 'Kevin Fischer'
            },
            "publisher": {
              "@type": "Organization",
              "name": "Tetherform",
              "logo": {
                "@type": "ImageObject",
                "url": "https://storage.googleapis.com/tetheros-public/tetherform_landing/logo-tetherform-small.png"
              }
            },
            "datePublished": new Date().toISOString(),
            "dateModified": new Date().toISOString(),
            "description": 'Find the perfect time to meet with your team.',
            "about": "A meeting coordination tool for aligning schedules when you don't share a calendar"
        };
        updateHeadTags(title, metaTags)
        updateSchemaOrgJSONLD(structuredData)
    }
    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [])
    if (isLoading) {
        return <Loader />;
    }
    return (
        <div className="meeting-finder">
            <div className="meeting-finder-landing">
                <h1 className="meeting-finder-main-title">Meeting Finder</h1>
                <p>Find the perfect time to meet with your team</p>
                
                <div 
                    className="meeting-finder-create-option"
                    onClick={() => navigate('/meeting-finder/create')}
                >
                    <div className="meeting-finder-option-icon">
                        <BsCalendarPlus />
                    </div>
                    <h2>Create New Poll</h2>
                    <p>Start a new poll to find the best meeting time</p>
                </div>

                <div className="meeting-finder-join-section">
                    <p className="meeting-finder-join-divider">or</p>
                    <form onSubmit={handlePollIdSubmit} className="meeting-finder-join-form">
                        <div className="meeting-finder-join-input-group">
                            <input
                                type="text"
                                value={pollId}
                                onChange={(e) => setPollId(e.target.value)}
                                placeholder="Enter a poll ID to join"
                                className={error ? 'error' : ''}
                            />
                            <button type="submit" className="meeting-finder-join-button">
                                Join Poll
                            </button>
                        </div>
                        {error && <div className="meeting-finder-join-error">{error}</div>}
                    </form>
                </div>

                <div className="meeting-finder-features">
                    <div className="meeting-finder-features-header">
                        <h2>Schedule meetings with ease</h2>
                        <p>Simple, efficient, and no account required</p>
                    </div>

                    <div className="meeting-finder-feature-grid">
                        <div className="meeting-finder-feature-card">
                            <div className="meeting-finder-feature-icon-wrapper">
                                <IoTime className="meeting-finder-feature-icon" />
                            </div>
                            <h3>Save Time</h3>
                            <p>No more back-and-forth emails. Create a poll in seconds and share with one link.</p>
                        </div>

                        <div className="meeting-finder-feature-card">
                            <div className="meeting-finder-feature-icon-wrapper">
                                <IoAnalytics className="meeting-finder-feature-icon" />
                            </div>
                            <h3>Smart Organization</h3>
                            <p>See responses organized by availability, making it easy to pick the best time.</p>
                        </div>

                        <div className="meeting-finder-feature-card">
                            <div className="meeting-finder-feature-icon-wrapper">
                                <IoMailUnread className="meeting-finder-feature-icon" />
                            </div>
                            <h3>Automatic Updates</h3>
                            <p>Get notified when people respond and easily send calendar invites when ready.</p>
                        </div>
                    </div>

                    <div className="meeting-finder-feature-cta">
                        <button 
                            onClick={() => navigate('/meeting-finder/create')}
                            className="meeting-finder-button-primary"
                        >
                            Find a meeting time
                        </button>
                    </div>
                </div>

                <div className="meeting-finder-integration">
                    <div className="meeting-finder-integration-content">
                        <h2>Part of the Tetherform Suite</h2>
                        <p>Looking for more ways to engage with your community?</p>
                        <div className="meeting-finder-integration-features">
                            <div className="meeting-finder-integration-feature">
                                <h4>Surveys That Think</h4>
                                <p>Create surveys that automatically analyze responses and generate insights.</p>
                            </div>
                            <div className="meeting-finder-integration-feature">
                                <h4>Smart Notifications</h4>
                                <p>Get automated reports and insights delivered straight to your inbox.</p>
                            </div>
                        </div>
                        <button 
                            onClick={() => window.location.href = '/'}
                            className="meeting-finder-button-secondary"
                        >
                            Learn More About Tetherform
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
