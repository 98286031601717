import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Home from './components/home/Home';
import Header from './components/header/Header';
import Register from './components/user_management/register/Register';
import PasswordReset from './components/user_management/reset/PasswordReset';
import ForgotPassword from './components/user_management/reset/ForgotPassword';
import Login from './components/user_management/login/Login';
import DashboardSplash from './components/dashboard/DashboardSplash';
import OrgSettings from './components/orgsettings/OrgSettings'
import OrganizationUsers from './components/organization_users/OrganizationUsers';
import OrgForms from './components/forms_list/OrgForms';
import FormBuilder from './components/form/FormBuilder';
import Dashboard from './components/dashboard/Dashboard';
import PricingPage from './components/home/PricingPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import TheFormPreview from './components/public/TheFormPreview';
import FormWrapper from './components/public/FormWrapper';
import SetupAccount from './components/account_setup/SetupAccount';
import MeetingFinderLanding from './components/tools/meeting_finder/MeetingFinderLanding';
import MeetingFinderCreator from './components/tools/meeting_finder/MeetingFinderCreator';
import MeetingFinderJoin from './components/tools/meeting_finder/MeetingFinderJoin';
import MeetingFinderManager from './components/tools/meeting_finder/MeetingFinderManager';
import BlogLanding from './components/blog/BlogLanding';
import BlogPost from './components/blog/BlogPost';
import './App.css';
import { OrganizationProvider } from './context/OrganizationContext';
import CreateNewForm from './components/forms_list/CreateNewForm';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <OrganizationProvider>
          <div className="app">
            <Header />
            <ErrorBoundary>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<OnlyPublicRoute><Register /></OnlyPublicRoute>} />
                <Route path="/login" element={<OnlyPublicRoute><Login /></OnlyPublicRoute>} />
                <Route path="/plans" element={<OnlyPublicRoute><PricingPage /></OnlyPublicRoute>} />
                <Route path="/blog" element={<BlogLanding />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/reset-password" element={<OnlyPublicRoute><PasswordReset /></OnlyPublicRoute>} />
                <Route path="/forgot-password" element={<OnlyPublicRoute><ForgotPassword /></OnlyPublicRoute>} />
                <Route path="/f/:formId" element={<FormWrapper />} />
                <Route path="/pr/:organizationId/:formId" element={<TheFormPreview />} />
                
                {/* Meeting Finder Routes */}
                <Route path="/meeting-finder" element={<MeetingFinderLanding />} />
                <Route path="/meeting-finder/manage/:pollId" element={<MeetingFinderManager />} />
                <Route path="/meeting-finder/create" element={<MeetingFinderCreator />} />
                <Route path="/meeting-finder/polls/:pollId" element={<MeetingFinderJoin />} />
                
                {/* Private routes */}
                <Route path="/welcome" element={<PrivateRoute><DashboardSplash /></PrivateRoute>} />
                <Route path="/account-setup" element={<PrivateRoute><SetupAccount /></PrivateRoute>} />
                
                {/* Organization routes */}
                <Route path="/o/:organizationId/*" element={
                  <PrivateRouteRequiringActiveOrganization>
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/forms" element={<OrgForms />} />
                        <Route path="/forms/create" element={<CreateNewForm />} />
                        <Route path="/forms/:formId" element={<FormBuilder />} />
                        <Route path="/settings" element={<OrgSettings />} />
                        <Route path="/users" element={<OrganizationUsers />} />
                      </Routes>
                    
                  </PrivateRouteRequiringActiveOrganization>
                } />
                
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </OrganizationProvider>
      </Router>
    </AuthProvider>
  );
}

function PrivateRoute({ children }) {
  // eslint-disable-next-line
  const { isAuthenticated, loading, isActiveAccount } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}

function PrivateRouteRequiringActiveOrganization({ children }) {
  const { isAuthenticated, loading, isActiveAccount } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  if (isActiveAccount && isAuthenticated) return children
  if (!isActiveAccount && isAuthenticated) return <Navigate to="/account-setup" />

  return <Navigate to="/login" />;
}

function OnlyPublicRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return isAuthenticated ? <Navigate to="/welcome" /> : children;
}