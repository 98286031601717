import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa';
import { registerUser } from '../../../api';
import { useAuth } from '../../../context/AuthContext';
import '../UserManagement.css'

export default function Register() {
    const [formData, setFormData] = useState({
      email: '',
      password: '',
      confirmPassword: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const { login, checkAuthStatus } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [passwordValid, setPasswordValid] = useState(false);
    const emailInputRef = useRef(null);
  
    useEffect(() => {
      const password = formData.password;
      const isValid = password.length >= 5 && password !== '12345' && password.toLowerCase() !== 'password';
      setPasswordValid(isValid);
      validateForm();
    // eslint-disable-next-line
    }, [formData]);
  
    useEffect(() => {
      emailInputRef.current?.focus();
    }, []);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    };
  
    const validateForm = () => {
      const isEmailValid = /\S+@\S+\.\S+/.test(formData.email);
      const isPasswordValid = formData.password.length >= 5 && formData.password !== '12345' && formData.password.toLowerCase() !== 'password';
      const passwordsMatch = formData.password === formData.confirmPassword;
      setIsFormValid(isEmailValid && isPasswordValid && passwordsMatch);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (isFormValid) {
        setIsSubmitting(true);
        try {
          const response = await registerUser(formData.email, formData.password);
          if (response.data.success) {
            await login(formData.email, formData.password);
            await checkAuthStatus();
            const params = new URLSearchParams(location.search);
            const redirectUrl = params.get('redirect') || '/welcome';
            navigate(redirectUrl);
          }
        } catch (error) {
          // Error handling without user feedback
          console.error('Registration failed:', error);
        } finally {
          setIsSubmitting(false);
        }
      }
    };
  
    return (
      <div className="registration-container">
        <h2>Create Account</h2>
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              ref={emailInputRef}
            />
          </div>
          <div className="form-group password-group">
            <div className="password-label">
              <label htmlFor="password">Password</label>
              <FaCheck className={`password-check ${passwordValid ? 'valid' : ''}`} />
            </div>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                className="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="password-input-wrapper">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <button
                type="button"
                className="toggle-password"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <button type="submit" disabled={isSubmitting || !isFormValid} className={isFormValid ? 'submit-button' : 'submit-button disabled'}>
            {isSubmitting ? 'Registering...' : 'Create Account'}
          </button>
        </form>
        <p className="login-link">
          Already have an account? <Link to={`/login${location.search}`}>Sign In</Link>
        </p>
      </div>
    );
  }
