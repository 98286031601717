import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './SplitButton.css';

const SplitButton = ({ primaryAction, primaryLabel, options, hasNewEdits }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="split-button" ref={dropdownRef}>
            {hasNewEdits ? <button className="split-button-primary" onClick={primaryAction}>{primaryLabel}</button> : <button className="split-button-primary split-button-primary-disabled">No changes</button>}
            <button className={`split-button-arrow ${!hasNewEdits && "split-button-primary-disabled"}`} onClick={() => setIsOpen(!isOpen)}>
                <FaChevronDown />
            </button>
            {isOpen && (
                <div className="split-button-dropdown">
                    {options.map((option, index) => (
                        <button key={index} onClick={() => {
                            option.action();
                            setIsOpen(false);
                        }}>
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SplitButton;