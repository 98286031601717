import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { submitPasswordReset } from '../../../api';

export default function PasswordReset() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resetStatus, setResetStatus] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const tokenParam = params.get('token');
      if (!tokenParam) {
        navigate('/login');
      } else {
        setToken(tokenParam);
      }
    }, [location, navigate]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        alert("Passwords don't match");
        return;
      }
      setIsLoading(true);
      try {
        await submitPasswordReset(email, password, token);
        setResetStatus('success');
      } catch (error) {
        console.error('Password reset error:', error);
        setResetStatus('error');
      } finally {
        setIsLoading(false);
      }
    };
  
    if (isLoading) {
      return (
        <div className="login-container reset-password-container">
          <h2>Resetting Password</h2>
          <div className="loading-spinner"></div>
        </div>
      );
    }
  
    if (resetStatus === 'success') {
      return (
        <div className="login-container reset-password-container">
          <h2>Password Reset Successful</h2>
          <p>Your password has been successfully reset.</p>
          <Link to="/login" className="reset-password-submit">Log In</Link>
        </div>
      );
    }
  
    if (resetStatus === 'error') {
      return (
        <div className="login-container reset-password-container">
          <h2>Password Reset Failed</h2>
          <p>Something went wrong while resetting your password. Please try again.</p>
          <Link to="/login" className="reset-password-submit">Return to Login</Link>
        </div>
      );
    }
  
    return (
      <div className="login-container reset-password-container">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit} className="login-form reset-password-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
          </div>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
          </div>
          <button type="submit" className="reset-password-submit">Reset Password</button>
        </form>
      </div>
    );
  };
