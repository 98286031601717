import React, { useState, useEffect } from 'react';
import { format, addMinutes } from 'date-fns';
import { IoClose } from 'react-icons/io5';
import { createGroupPoll } from '../../../../api';
import '../../MeetingFinder.css';

export default function ConfirmationPage({ pollData, onSubmit, onBack }) {
    const [selectedTimes, setSelectedTimes] = useState(pollData.selectedTimes || []);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    // Keep pollData in sync with selectedTimes
    useEffect(() => {
        if (pollData.selectedTimes !== selectedTimes) {
            pollData.selectedTimes = selectedTimes;
        }
    }, [selectedTimes, pollData]);

    const handleBack = () => {
        // Pass updated times back to previous page
        onBack({
            ...pollData,
            selectedTimes
        });
    };

    const formatTimeSlot = (timeStr) => {
        // Parse the 24h time string into a Date object
        const [hours, minutes] = timeStr.split(':');
        const startTime = new Date();
        startTime.setHours(parseInt(hours), parseInt(minutes));
        
        // Calculate end time based on duration
        const endTime = addMinutes(startTime, pollData.duration);
        
        // Format as range
        const startFormat = format(startTime, 'h:mm');
        const endFormat = format(endTime, 'h:mm a');
        
        return `${startFormat} - ${endFormat}`;
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            setError(null);

            // Prepare API payload
            const apiPayload = {
                eventName: pollData.eventName,
                duration: pollData.duration,
                organizer: {
                    name: pollData.name,
                    email: pollData.email
                },
                timezone: pollData.timezone || 'America/Chicago',
                proposedTimes: selectedTimes.sort().map(slotKey => {
                    const [date, time] = slotKey.split('_');
                    return `${date}T${time}`; // Format: "2024-03-20T14:30"
                })
            };

            // Debug logging
            console.group('ConfirmationPage - API Payload Debug');
            console.log('API Payload:', apiPayload);
            console.log('Total Slots:', apiPayload.proposedTimes.length);
            console.groupEnd();

            // Create the poll
            const response = await createGroupPoll(apiPayload);
            console.log(response)
            
            // Pass the created poll data to the next step
            onSubmit({
                ...pollData,
                selectedTimes,
                pollId: response.data.poll.pollId,
                pollUrl: `${window.location.origin}/polls/${response.data.poll.pollId}`
            });

        } catch (err) {
            console.error('Failed to create poll:', err);
            setError('Failed to create poll. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Group slots by date
    const groupedSlots = selectedTimes.reduce((groups, slotKey) => {
        const [dateStr, timeStr] = slotKey.split('_');
        // Create date using the full ISO string to preserve timezone
        const date = new Date(`${dateStr}T${timeStr}`);
        const dateKey = format(date, 'yyyy-MM-dd');
        
        if (!groups[dateKey]) {
            groups[dateKey] = {
                displayDate: format(date, 'EEEE, MMMM d'),
                slots: []
            };
        }
        
        groups[dateKey].slots.push({
            slotKey,
            time: timeStr,
            displayTime: formatTimeSlot(timeStr)
        });
        
        // Sort slots within the group by time
        groups[dateKey].slots.sort((a, b) => a.time.localeCompare(b.time));
        
        return groups;
    }, {});

    const handleRemoveSlot = (slotKey) => {
        setSelectedTimes(prev => prev.filter(slot => slot !== slotKey));
    };

    // Sort dates chronologically
    const sortedDates = Object.entries(groupedSlots)
        .sort(([dateA], [dateB]) => dateA.localeCompare(dateB));

    return (
        <div className="meeting-finder-confirmation meeting-finder-create-confirmation">
            <div className="meeting-finder-manager-header">
                <h1>{pollData.eventName}</h1>
                <div className="meeting-finder-manager-meta">
                    <div className="meeting-finder-manager-organizer">
                        Organized by {pollData.name} ({pollData.email})
                    </div>
                    <div className="meeting-finder-manager-details">
                        <span>{pollData.duration} minutes</span>
                        <span>•</span>
                        <span>{pollData.timezone}</span>
                    </div>
                </div>
            </div>

            <div className="meeting-finder-confirmation-section">
                <h3>Proposed Times</h3>
                <div className="meeting-finder-confirmation-times">
                    {sortedDates.map(([dateKey, { displayDate, slots }]) => (
                        <div key={dateKey} className="meeting-finder-time-group">
                            <div className="meeting-finder-time-group-header">
                                {displayDate}
                            </div>
                            <div className="meeting-finder-time-group-slots">
                                {slots.map(({ slotKey, displayTime }) => (
                                    <div key={slotKey} className="meeting-finder-time-pill">
                                        <span className="meeting-finder-time-pill-text">
                                            {displayTime}
                                        </span>
                                        <button
                                            className="meeting-finder-time-pill-remove"
                                            onClick={() => handleRemoveSlot(slotKey)}
                                            aria-label="Remove time slot"
                                        >
                                            <IoClose />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            {error && (
                <div className="meeting-finder-error-message">
                    {error}
                </div>
            )}

            <div className="meeting-finder-form-buttons">
                <button 
                    type="button" 
                    onClick={handleBack} 
                    className="meeting-finder-button-secondary"
                    disabled={isSubmitting}
                >
                    Back
                </button>
                <button 
                    onClick={handleSubmit}
                    className="meeting-finder-button-primary"
                    disabled={selectedTimes.length === 0 || isSubmitting}
                >
                    {isSubmitting ? 'Creating Poll...' : 'Create Poll'}
                </button>
            </div>
        </div>
    );
} 