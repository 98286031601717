import React, { useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'
import { useOrganization } from '../../context/OrganizationContext';
import './header.css'
import { IoMenuSharp, IoCloseSharp, IoChevronDownSharp } from "react-icons/io5";
import { BsCalendarCheck } from "react-icons/bs";

const hideHeaderPaths = ['/f', '/pr'];

const resourcesMenu = [
    {
        header: "Tools",
        items: [
            {
                name: "Meeting Finder Tool",
                description: "Find the perfect time to meet",
                icon: BsCalendarCheck,
                path: "/meeting-finder"
            },
            // {
            //     name: "Simple Live Poll Tool",
            //     description: "Quick polls for live audiences",
            //     icon: BsPeople,
            //     path: "/live-poll"
            // }
        ]
    }
];

export default function Header() {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showResources, setShowResources] = useState(false);
    const [isMenuHiding, setIsMenuHiding] = useState(false);
    const menuTimeoutRef = useRef(null);
    const { user, logout } = useAuth();
    const { organization } = useOrganization();
    const navigate = useNavigate();
    const location = useLocation();

    const shouldHideHeader = hideHeaderPaths.some(path => location.pathname.startsWith(path));
    if (shouldHideHeader) return null;

    const handleLogout = () => {
        if (!user) return;
        logout();
        navigate("/");
    }

    const handleMobileNavigation = (location) => {
        setMenuIsOpen(false)
        if (location === "logout") return handleLogout()
        navigate(location)
    }

    const handleMenuEnter = () => {
        if (menuTimeoutRef.current) {
            clearTimeout(menuTimeoutRef.current);
        }
        setIsMenuHiding(false);
        setShowResources(true);
    };

    const handleMenuLeave = () => {
        setIsMenuHiding(true);
        menuTimeoutRef.current = setTimeout(() => {
            setShowResources(false);
            setIsMenuHiding(false);
        }, 150); // Match this with the CSS transition duration
    };

    return (
        <div className="header">

            {/* DESKTOP HEADER */}
            <div className="header-desktop header-space-control header-align-left">
                {!user &&
                <div className="header-option-unauthed-names">
                    <div className="header-option-name resources-dropdown" onMouseEnter={handleMenuEnter} onMouseLeave={handleMenuLeave}>
                        Resources <IoChevronDownSharp/>
                        {showResources && (
                            <div className={`resources-menu ${isMenuHiding ? 'hiding' : ''}`}>
                                {resourcesMenu.map((section, index) => (
                                    <div key={index} className="resources-section">
                                        <div className="resources-section-header">{section.header}</div>
                                        {section.items.map((item, itemIndex) => (
                                            <div 
                                                key={itemIndex} 
                                                className="resources-item"
                                                onClick={() => navigate(item.path)}
                                            >
                                                <div className="resources-item-icon">
                                                    <item.icon />
                                                </div>
                                                <div className="resources-item-content">
                                                    <div className="resources-item-name">{item.name}</div>
                                                    <div className="resources-item-description">{item.description}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="header-option-name" onClick={() => navigate("/plans")}>Pricing</div>
                    <div className="header-option-name" onClick={() => navigate("/blog")}>Blog</div>
                </div>
                }
                {user && <div className="header-logo" onClick={() => navigate("/")}>Tether<span>form</span></div>}
            </div>
            {!user &&
            <div className="header-desktop header-auth-options header-option-names header-space-control header-align-center">
                <div className="header-logo" onClick={() => navigate("/")}>Tether<span>form</span></div>
            </div>
            }
            {!user && <div className="header-desktop header-auth-options header-space-control header-align-right">
                <span onClick={() => navigate("/register")} className="header-auth-option-login">Sign Up</span>
                <span onClick={() => navigate("/login")}>Login</span>
            </div>
            }
            {organization && user &&
            <div className="header-desktop header-auth-options header-space-control header-align-center"></div>
            }
            {user && <div className="header-desktop header-auth-options header-space-control header-align-right">
                <span onClick={handleLogout}>Log out</span>
            </div>
            }

            {/* MOBILE HEADER */}
            <div className="header-mobile-outer">
                <div className="header-mobile">
                    <div className="header-logo" onClick={() => navigate("/")}>Tether<span>form</span></div>
                    {!menuIsOpen && <div className="header-mobile-menu-icon" onClick={() => setMenuIsOpen(true)}><IoMenuSharp/></div>}
                    {menuIsOpen && <div className="header-mobile-menu-icon" onClick={() => setMenuIsOpen(false)}><IoCloseSharp/></div>}
                </div>
            </div>
            {menuIsOpen &&
            <div className="header-mobile-menu">
                {!user && <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/login")}>Login</div>}
                {!user && <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/plans")}>Pricing</div>}
                {!user && <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/register")}>Sign up</div>}
                {!user && <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/blog")}>Blog</div>}
                {!user && <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("/meeting-finder")}>Meeting Finder</div>}
                {user && <div className="header-mobile-menu-item" onClick={() => handleMobileNavigation("logout")}>Log out</div>}
            </div>
            }
        </div>
    )
}
