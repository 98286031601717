import React, { useState, useEffect } from 'react';
import '../TheForm.css';

const MultipleChoiceQuestion = ({ question, value, onChange, primaryColor, contrastColor }) => {
    const [otherValue, setOtherValue] = useState('');
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    useEffect(() => {
        if (value && !question.options.options.includes(value) && value !== 'Other') {
            setOtherValue(value);
            setIsOtherSelected(true);
        } else {
            setIsOtherSelected(value === 'Other');
            if (value !== 'Other') {
                setOtherValue('');
            }
        }
    }, [value, question.options.options]);

    const handleChange = (option) => {
        if (option === 'Other') {
            setIsOtherSelected(true);
            setOtherValue('');
            onChange('Other');
        } else {
            setIsOtherSelected(false);
            setOtherValue('');
            onChange(option);
        }
    };

    const handleOtherInputChange = (e) => {
        const inputValue = e.target.value.slice(0, 280);
        setOtherValue(inputValue);
        onChange(inputValue || 'Other');
    };

    const getCheckboxStyle = (isSelected) => {
        if (isSelected) {
            return {
                border: `1px solid ${primaryColor}`,
                backgroundColor: primaryColor,
                color: contrastColor
            }
        }
        return {}
    }

    return (
        <div className="the-form-multiple-choice">
            <div className="the-form-checkbox-options">
                {question.options?.options.map((option, index) => (
                    <div 
                        style={getCheckboxStyle(value === option)} 
                        key={index} 
                        className={`the-form-checkbox-option the-form-checkbox-option-one ${(value === option) ? "selected" : ""}`} 
                        onClick={() => handleChange(option)}
                    >
                        {option}
                    </div>
                ))}
                {question.options?.allowOther && (
                    <div 
                        style={getCheckboxStyle(isOtherSelected)} 
                        className={`the-form-checkbox-option the-form-checkbox-option-one ${isOtherSelected ? "selected" : ""}`} 
                        onClick={() => handleChange('Other')}
                    >
                        Other
                    </div>
                )}
            </div>
            {question.options?.allowOther && isOtherSelected && (
                <input
                    type="text" 
                    value={otherValue} 
                    onChange={handleOtherInputChange} 
                    placeholder="Enter other option" 
                    className="the-form-other-input" 
                    maxLength={280}
                />
            )}
        </div>
    );
};

export default MultipleChoiceQuestion;
