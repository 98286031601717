import React, { useState, useReducer, useEffect, useRef } from 'react';
import { submitFormResponse } from '../../api';
import { getContrastColor } from '../../helpers/Helpers';
import './TheForm.css';
import TextQuestion from './question_types/TextQuestion';
import RatingQuestion from './question_types/RatingQuestion';
import SelectMultipleQuestion from './question_types/SelectMultipleQuestion';
import MultipleChoiceQuestion from './question_types/MultipleChoiceQuestion';

const isValidColor = (color) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== '';
};

const answerReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_ANSWER':
            return { ...state, [action.questionId]: action.value };
        default:
            return state;
    }
};

const hasValidOptions = (question) => {
    return question.options && Array.isArray(question.options.options) && question.options.options.length > 0;
};

export default function TheForm({ formData, formId, preview }) {
    const [answers, dispatchAnswer] = useReducer(answerReducer, {});
    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showWatermark, setShowWatermark] = useState(false);
    const titleRef = useRef(null);

    const primaryColor = isValidColor(formData.design_primary_color) ? formData.design_primary_color : 'var(--color-brand)';
    const contrastColor = getContrastColor(primaryColor);

    const handleInputChange = (questionId, value) => {
        dispatchAnswer({ type: 'UPDATE_ANSWER', questionId, value });
    };

    const validateRequiredQuestions = () => {
        const requiredQuestions = formData.structure.filter(q => 
            q.required && 
            ((q.type !== 'multiple-choice' && q.type !== 'select-multiple') || hasValidOptions(q))
        );
        const unansweredQuestions = requiredQuestions.filter(q => {
            const answer = answers[q.id];
            if (answer === undefined || answer === null || answer === '') return true;
            if (Array.isArray(answer) && answer.length === 0) return true;
            return false;
        });

        if (unansweredQuestions.length > 0) {
            setErrorMessage(`Please answer all required questions before submitting.`);
            unansweredQuestions.forEach(q => {
                const element = document.getElementById(`question-${q.id}`);
                if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
            return false;
        }
        return true;
    };

    const submitForm = async (e) => {
        e.preventDefault();
        setErrorMessage(null);

        if (!validateRequiredQuestions()) {
            return;
        }

        if (preview) {
            console.log('Form submitted (preview mode):', answers);
            setSubmitStatus('preview');
            return;
        }

        // Check if there are any non-empty answers
        const hasNonEmptyAnswers = Object.values(answers).some(value => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            if (typeof value === 'number') {
                return true;
            }
            return value.trim() !== '';
        });

        if (!hasNonEmptyAnswers) {
            setSubmitStatus('success');
            return;
        }

        try {
            const trimmedAnswers = Object.entries(answers).reduce((acc, [id, value]) => {
                acc[id] = Array.isArray(value) ? value.map(v => v.trim()) : (typeof value === 'string' ? value.trim() : value);
                return acc;
            }, {});

            submitFormResponse(formId, Object.entries(trimmedAnswers).map(([id, value]) => ({ id, value })));
            setSubmitStatus('success');
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('An error occurred while submitting the form. Please try again.');
        }
    };

    const closeWindow = () => {
        window.close();
    };

    const renderQuestionInput = (question) => {
        const commonProps = {
            question,
            value: answers[question.id] || '',
            onChange: (value) => handleInputChange(question.id, value),
            primaryColor,
            contrastColor
        };

        switch (question.type) {
            case 'text':
                return <TextQuestion {...commonProps} />;
            case 'rating':
                if (question.scale && typeof question.scale.min === 'number' && typeof question.scale.max === 'number') {
                    return <RatingQuestion {...commonProps} />;
                }
                console.warn(`Rating question ${question.id} has an invalid or missing scale`);
                return null;
            case 'select-multiple':
                return hasValidOptions(question) ? <SelectMultipleQuestion {...commonProps} /> : null;
            case 'multiple-choice':
                return hasValidOptions(question) ? <MultipleChoiceQuestion {...commonProps} /> : null;
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (titleRef.current) {
                const rect = titleRef.current.getBoundingClientRect();
                setShowWatermark(rect.bottom < 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        // Set the document title to match the form name
        document.title = formData.title || 'Tetherform';
    }, [formData.title]);

    const { title, design_dark_mode, structure } = formData;

    if (!structure || !Array.isArray(structure)) {
        console.error('Invalid form structure:', structure);
        return <div className="the-form-error">Error: Invalid form structure</div>;
    }

    if (submitStatus === 'success') {
        return (
            <div className={`the-form-container ${design_dark_mode ? 'the-form-dark-mode' : ''}`} style={{'--primary-color': primaryColor, '--contrast-color': contrastColor}}>
                
                <div className="the-form-title" style={{paddingTop: "120px", display: "flex", justifyContent: "center"}}>
                    <h1>Thank you!</h1>
                </div>
                <div className="the-form-content">
                    <p className="the-form-thank-you-message">Your response has been submitted successfully.</p>
                    <button onClick={closeWindow} className="the-form-close-button" style={{backgroundColor: primaryColor, color: contrastColor}}>Close Window</button>
                </div>
                <div className="the-form-attribution"><img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/logo-tetherform-small.png" alt="Tetherform logo"/> <a href="/">Designed with Tetherform</a></div>
            </div>
        );
    }

    return (
        <div className={`the-form-container ${design_dark_mode ? 'the-form-dark-mode' : ''}`} style={{'--primary-color': primaryColor, '--contrast-color': contrastColor}}>
            <div className="the-form-title">
                <div className="the-form-title-presentation" style={{borderColor: primaryColor}}>
                    <h1 ref={titleRef}>{title}</h1>
                    <span>All responses are anonymous</span>
                    {formData.introduction_message && <div className="the-form-introduction-message">{formData.introduction_message}</div>}
                </div>
            </div>
            {showWatermark && (
                <div className="the-form-watermark" title={title}>
                    {title}
                </div>
            )}
            {errorMessage && (
                <div className="the-form-error-message" style={{backgroundColor: primaryColor, color: contrastColor}}>
                    {errorMessage}
                </div>
            )}
            <div className="the-form-content">
                <form onSubmit={submitForm} className="the-form">
                    {structure.map((question, index) => {
                        if (
                            (question.type === 'rating' && (!question.scale || typeof question.scale.min !== 'number' || typeof question.scale.max !== 'number')) ||
                            ((question.type === 'multiple-choice' || question.type === 'select-multiple') && !hasValidOptions(question))
                        ) {
                            return null;
                        }
                        return (
                            <div key={question.id} id={`question-${question.id}`} className="the-form-question" style={{borderLeft: `2px solid ${primaryColor}`}}>
                                <div className="the-form-question-number">Question {index + 1}{question.required && <span className="the-form-required" style={{color: primaryColor}}>*</span>}</div>
                                <div className="the-form-label-and-description-container">
                                    <label className="the-form-label">{question.question}</label>
                                    {question.description && <p className="the-form-description">{question.description}</p>}
                                </div>

                                {renderQuestionInput(question, index)}
                            </div>
                        );
                    })}
                    <button type="submit" className="the-form-submit-button" style={{backgroundColor: primaryColor, color: contrastColor}}>Submit</button>
                    <div className="the-form-attribution the-form-attribution-active"><img src="https://storage.googleapis.com/tetheros-public/tetherform_landing/logo-tetherform-small.png" alt="Tetherform logo"/> Designed with Tetherform</div>
                </form>
                {submitStatus === 'preview' && (
                    <div className="the-form-submit-status preview" style={{borderColor: primaryColor, color: primaryColor}}>
                        Form submitted (preview mode)
                    </div>
                )}
            </div>
        </div>
    );
}
