import { format, addMinutes, parseISO } from 'date-fns';

export const COMMON_TIMEZONES = [
    { value: 'America/New_York', label: 'Eastern Time (ET)' },
    { value: 'America/Chicago', label: 'Central Time (CT)' },
    { value: 'America/Denver', label: 'Mountain Time (MT)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
    { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
    { value: 'Pacific/Honolulu', label: 'Hawaii Time (HT)' },
    { value: 'America/Phoenix', label: 'Arizona Time (AZ)' },
    { value: 'Europe/London', label: 'London (GMT)' },
    { value: 'Europe/Paris', label: 'Central European Time (CET)' },
    { value: 'Asia/Tokyo', label: 'Japan Time (JST)' }
];

export const formatTimeSlot = (dateStr, timeStr, duration = 30) => {
    const date = parseISO(dateStr);
    const [hours, minutes] = timeStr.split(':');
    const startTime = new Date(date.setHours(parseInt(hours), parseInt(minutes)));
    const endTime = addMinutes(startTime, duration);
    
    return {
        displayTime: `${format(startTime, 'h:mm')} - ${format(endTime, 'h:mm a')}`,
        startTime,
        endTime
    };
};

export const convertTimeZone = (dateStr, timeStr) => {
    const date = parseISO(dateStr);
    const [hours, minutes] = timeStr.split(':');
    date.setHours(parseInt(hours), parseInt(minutes));
    
    return format(date, 'HH:mm');
}; 