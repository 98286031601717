import React, { useState, useEffect } from 'react';
import '../TheForm.css';

const SelectMultipleQuestion = ({ question, value, onChange, primaryColor, contrastColor }) => {
    const [otherValue, setOtherValue] = useState('');
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    useEffect(() => {
        if (Array.isArray(value)) {
            const otherOption = value.find(v => !question.options.options.includes(v) && v !== 'Other');
            if (otherOption) {
                setOtherValue(otherOption);
                setIsOtherSelected(true);
            } else {
                setIsOtherSelected(value.includes('Other'));
            }
        }
    }, [value, question.options.options]);

    const handleChange = (option) => {
        let newValue = Array.isArray(value) ? [...value] : [];
        if (option === 'Other') {
            if (newValue.includes('Other')) {
                newValue = newValue.filter(item => item !== 'Other' && question.options.options.includes(item));
                setIsOtherSelected(false);
            } else {
                newValue.push('Other');
                setIsOtherSelected(true);
            }
        } else {
            const index = newValue.indexOf(option);
            if (index > -1) {
                newValue.splice(index, 1);
            } else {
                newValue.push(option);
            }
        }
        onChange(newValue);
    };

    const handleOtherInputChange = (e) => {
        const inputValue = e.target.value.slice(0, 280);
        setOtherValue(inputValue);
        let newValue = Array.isArray(value) ? [...value] : [];
        
        // Remove any previous custom "Other" value
        newValue = newValue.filter(item => question.options.options.includes(item) || item === 'Other');
        
        if (inputValue && !newValue.includes('Other')) {
            newValue.push('Other');
        }
        
        if (inputValue) {
            newValue.push(inputValue);
        }
        
        onChange(newValue);
    };

    const getCheckboxStyle = (isSelected) => {
        if (isSelected) {
            return {
                border: `1px solid ${primaryColor}`,
                backgroundColor: primaryColor,
                color: contrastColor
            }
        }
        return {}
    }

    return (
        <div className="the-form-select-multiple">
            <div className="the-form-checkbox-options">
                {question.options?.options.map((option, index) => (
                    <div 
                        style={getCheckboxStyle((Array.isArray(value) && value.includes(option)))} 
                        key={index} 
                        className={`the-form-checkbox-option ${(Array.isArray(value) && value.includes(option)) ? "selected" : ""}`} 
                        onClick={() => handleChange(option)}
                    >
                        {option}
                    </div>
                ))}
                {question.options?.allowOther && (
                    <div 
                        style={getCheckboxStyle(isOtherSelected)} 
                        className={`the-form-checkbox-option ${isOtherSelected ? "selected" : ""}`} 
                        onClick={() => handleChange('Other')}
                    >
                        Other
                    </div>
                )}
            </div>
            {question.options?.allowOther && isOtherSelected && (
                <input
                    type="text" 
                    value={otherValue} 
                    onChange={handleOtherInputChange} 
                    placeholder="Enter other option" 
                    className="the-form-other-input" 
                    maxLength={280}
                />
            )}
        </div>
    );
};

export default SelectMultipleQuestion;
