import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FormBuilderOptions.css';

const FormBuilderSchedule = ({ scheduleState, onScheduleUpdate }) => {
    const handleStartDateChange = (date) => {
        let newEndDate = scheduleState.schedule_end;
        if (scheduleState.schedule_end && date && date >= scheduleState.schedule_end) {
            newEndDate = new Date(date);
            newEndDate.setDate(newEndDate.getDate() + 1);
        }
        onScheduleUpdate({ 
            schedule_start: date,
            schedule_end: newEndDate
        });
    };
    const handleEndDateChange = (date) => {
        onScheduleUpdate({ schedule_end: date });
    };
    const getMinEndDate = () => {
        if (scheduleState.schedule_start) {
            const minDate = new Date(scheduleState.schedule_start);
            minDate.setDate(minDate.getDate() + 1);
            return minDate;
        }
        return new Date();
    };
    return (
        <div className="form-builder-schedule">
            <div className="form-builder-design-options-header-row"><h2>Schedule</h2></div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Start Date</h4>
                    <p>The earliest anyone can access the form. Leave blank to allow immediate form access.</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div style={{width: "50%"}}>
                        <DatePicker
                        selected={scheduleState.schedule_start}
                        onChange={handleStartDateChange}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select start date"
                        isClearable
                        minDate={new Date()}
                        />
                    </div>
                    
                </div>
            </div>

            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Close Date</h4>
                    <p>The form will no longer be available after this date. Leave blank to allow access forever.</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div style={{width: "50%"}}>
                        <DatePicker selected={scheduleState.schedule_end} onChange={handleEndDateChange} dateFormat="MMMM d, yyyy" placeholderText="Select end date" isClearable minDate={getMinEndDate()}/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FormBuilderSchedule;
