import React, { useState, useRef, useEffect } from 'react'
import './QuestionCanvas.css'
import { FaList, FaStar, FaCheckSquare, FaFont, FaPlus } from 'react-icons/fa'
import { MdOpenInNew } from "react-icons/md";
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import AddQuestionFloatingModal from './AddQuestionFloatingModal'
import ModalQuestionManage from './ModalQuestionManage'
import ModalDeleteQuestion from './ModalDeleteQuestion'
import AddQuestionButton from './canvas_misc/AddQuestionButton'
import QuestionItem from './canvas_misc/QuestionItem'
import { v4 as uuidv4 } from 'uuid';

const questionTypes = [
    { id: 'multiple-choice', icon: FaList, label: 'Multiple Choice' },
    { id: 'rating', icon: FaStar, label: 'Rating' },
    { id: 'select-multiple', icon: FaCheckSquare, label: 'Select Multiple' },
    { id: 'text', icon: FaFont, label: 'Text' },
];

const QuestionConnector = ({ onClick }) => (
    <div className="question-canvas-connector">
        <div 
            className="question-canvas-add-button"
            onClick={onClick}
        >
            <FaPlus />
        </div>
    </div>
);

const FloatingMenu = ({ questionTypes, onItemClick }) => (
    <div className="question-canvas-floating-menu">
        {questionTypes.map((type) => (
            <div 
                key={type.id} 
                className="question-canvas-floating-menu-item"
                onClick={() => onItemClick(type.id)}
            >
                <div className="question-canvas-floating-menu-icon-container">
                    <type.icon className="question-canvas-floating-menu-icon" />
                </div>
                <span className="question-canvas-floating-menu-label">{type.label}</span>
            </div>
        ))}
    </div>
);

export default function QuestionCanvas({ 
    questions, 
    onQuestionsChange, 
    onAddQuestion, 
    onUpdateQuestion, 
    onDeleteQuestion,
    openPreview
}) {
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
    const [addQuestionPosition, setAddQuestionPosition] = useState({ x: 0, y: 0 });
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    const addQuestionRef = useRef(null);
    const [showQuestionManageModal, setShowQuestionManageModal] = useState(false);
    const [selectedQuestionType, setSelectedQuestionType] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [insertIndex, setInsertIndex] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);
    const [newQuestionId, setNewQuestionId] = useState(null);
    const handleQuestionClick = (event, question) => {
        handleEditQuestion(question);
    };
    const handleAddQuestionClick = (event, index) => {
        const rect = containerRef.current.getBoundingClientRect();
        const scrollTop = containerRef.current.scrollTop;
        setAddQuestionPosition({ 
            x: event.clientX - rect.left, 
            y: event.clientY - rect.top + scrollTop
        });
        setShowAddQuestionModal(true);
        setInsertIndex(index + 1);
        setEditingQuestion(null);
    };
    const handleSelectQuestionType = (questionType) => {
        setSelectedQuestionType(questionType);
        setShowAddQuestionModal(false);
        setShowQuestionManageModal(true);
    };
    const handleClickOutside = (event) => {
        if (addQuestionRef.current && !addQuestionRef.current.contains(event.target)) {
            setShowAddQuestionModal(false);
        }
    };
    const handleSaveQuestion = (questionData) => {
        if (editingQuestion) {
            const updatedQuestions = questions.map(q => 
                q.id === editingQuestion.id ? questionData : q
            );
            onQuestionsChange(updatedQuestions);
        } else {
            onAddQuestion(questionData, insertIndex);
        }
        setShowQuestionManageModal(false);
        setEditingQuestion(null);
        setInsertIndex(null);
    };
    const handleEditQuestion = (question) => {
        setEditingQuestion(question);
        setSelectedQuestionType(question.type);
        setShowQuestionManageModal(true);
    };
    const handleFloatingMenuItemClick = (questionType) => {
        setSelectedQuestionType(questionType);
        setShowQuestionManageModal(true);
        setEditingQuestion(null);
        setInsertIndex(questions.length);
    };
    const handleDeleteIconClick = (event, question) => {
        event.stopPropagation();
        setQuestionToDelete(question);
        setShowDeleteModal(true);
    };
    const handleDeleteConfirm = () => {
        if (questionToDelete) {
            onDeleteQuestion(questionToDelete.id);
            setShowDeleteModal(false);
            setQuestionToDelete(null);
        }
    };
    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
        setQuestionToDelete(null);
    };
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;

        // Check if the item was dropped in a different position
        if (source.index !== destination.index) {
            const newQuestions = Array.from(questions);
            const [reorderedItem] = newQuestions.splice(source.index, 1);
            newQuestions.splice(destination.index, 0, reorderedItem);

            onQuestionsChange(newQuestions);
        }
    };
    const handleDuplicateQuestion = (event, question, index) => {
        event.stopPropagation();
        
        // Create duplicate question with new ID
        const duplicatedQuestion = {
            ...question,
            id: uuidv4()
        };

        // Insert duplicated question after the original
        const newQuestions = [...questions];
        newQuestions.splice(index + 1, 0, duplicatedQuestion);
        
        // Update questions array
        onQuestionsChange(newQuestions);
        
        // Set the new question ID for highlighting
        setNewQuestionId(duplicatedQuestion.id);
        
        // Clear the highlight after animation
        setTimeout(() => {
            setNewQuestionId(null);
        }, 200);
    };
    useEffect(() => {
        if (showAddQuestionModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAddQuestionModal]);
    return (
        <>
            <div className="question-canvas-container" ref={containerRef}>
                {questions.length > 0 &&
                <div className="question-canvas-container-preview-buttons" onClick={openPreview}>
                    Open preview
                    <MdOpenInNew/>
                </div>}
                <div className="question-canvas-scroll-container">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="questions">
                            {(provided) => (
                                <div 
                                    className="question-canvas-content" 
                                    ref={(el) => {
                                        contentRef.current = el;
                                        provided.innerRef(el);
                                    }}
                                    {...provided.droppableProps}
                                >
                                    {questions.length === 0 ? (
                                        <AddQuestionButton onClick={(e) => handleAddQuestionClick(e, -1)} />
                                    ) : (
                                        questions.map((question, index) => (
                                            <React.Fragment key={question.id}>
                                                <QuestionItem 
                                                    question={question}
                                                    index={index}
                                                    onQuestionClick={handleQuestionClick}
                                                    onDeleteIconClick={handleDeleteIconClick}
                                                    onDuplicateIconClick={handleDuplicateQuestion}
                                                    isNew={question.id === newQuestionId}
                                                />
                                                {index < questions.length - 1 && (
                                                    <QuestionConnector onClick={(e) => handleAddQuestionClick(e, index)} />
                                                )}
                                            </React.Fragment>
                                        ))
                                    )}
                                    {provided.placeholder}
                                    {questions.length > 0 && (
                                        <>
                                            <QuestionConnector onClick={(e) => handleAddQuestionClick(e, questions.length - 1)} />
                                            <div className="question-canvas-end">
                                                <span>End</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            {showAddQuestionModal && (
                <div ref={addQuestionRef}>
                    <AddQuestionFloatingModal 
                        onSelectQuestionType={handleSelectQuestionType}
                        style={{
                            position: 'absolute',
                            left: `${addQuestionPosition.x}px`,
                            top: `${addQuestionPosition.y}px`,
                            transform: 'translate(-50%, 0)',
                        }}
                    />
                </div>
            )}
            {showQuestionManageModal && (
                <ModalQuestionManage 
                    onClose={() => {
                        setShowQuestionManageModal(false);
                        setEditingQuestion(null);
                        setInsertIndex(null);
                    }}
                    questionType={selectedQuestionType}
                    existingQuestion={editingQuestion}
                    onSave={handleSaveQuestion}
                />
            )}
            {showDeleteModal && (
                <ModalDeleteQuestion 
                    onConfirm={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                />
            )}
            <FloatingMenu 
                questionTypes={questionTypes}
                onItemClick={handleFloatingMenuItemClick}
            />
        </>
    );
}