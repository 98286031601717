import React from 'react'
import { FaList, FaStar, FaCheckSquare, FaFont, FaTimes, FaCopy } from 'react-icons/fa'
import { Draggable } from 'react-beautiful-dnd'

const questionTypes = [
    { id: 'multiple-choice', icon: FaList, label: 'Multiple Choice' },
    { id: 'rating', icon: FaStar, label: 'Rating' },
    { id: 'select-multiple', icon: FaCheckSquare, label: 'Select Multiple' },
    { id: 'text', icon: FaFont, label: 'Text' },
];

export default function QuestionItem({ question, index, onQuestionClick, onDeleteIconClick, onDuplicateIconClick, isNew }) {
    const QuestionIcon = questionTypes.find(type => type.id === question.type)?.icon;
    
    const handleClick = (e, provided, question) => {
        if (!provided.dragHandleProps.isDragging) {
            onQuestionClick(e, question);
        }
    };

    return (
        <Draggable draggableId={question.id} index={index}>
            {(provided, snapshot) => (
                <div 
                    className={`question-canvas-question-item ${snapshot.isDragging ? 'is-dragging' : ''} ${isNew ? 'highlight-new' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={(e) => handleClick(e, provided, question)}
                >
                    <div className="question-canvas-question-icon-container">
                        {QuestionIcon && <QuestionIcon className="question-canvas-question-icon" />}
                    </div>
                    <div className="question-canvas-question-text-container">
                        <span className="question-canvas-question-number">Question {index + 1}</span>
                        <span className="question-canvas-question-text">{question.question.trim() || `${questionTypes.find(type => type.id === question.type).label} question`}</span>
                    </div>
                    <div 
                        className="question-canvas-delete-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteIconClick(e, question);
                        }}
                    >
                        <FaTimes />
                    </div>
                    <div 
                        className="question-canvas-duplicate-icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDuplicateIconClick(e, question, index);
                        }}
                    >
                        <FaCopy />
                    </div>
                </div>
            )}
        </Draggable>
    )
}