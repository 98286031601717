import React from 'react';
import { FaStar, FaCircle, FaHeart, FaThumbsUp, FaFlag, FaChevronDown } from 'react-icons/fa';

const scaleOptions = [
    { value: '1-3', label: '1-3' },
    { value: '1-5', label: '1-5' },
    { value: '1-10', label: '1-10' },
];

const symbolOptions = [
    { value: 'star', icon: FaStar, label: 'Stars' },
    { value: 'circle', icon: FaCircle, label: 'Dots' },
    { value: 'heart', icon: FaHeart, label: 'Hearts' },
    { value: 'thumbsUp', icon: FaThumbsUp, label: 'Thumbs Up' },
    { value: 'flag', icon: FaFlag, label: 'Flags' },
];

const RatingOptions = ({ options, onChange }) => {
    const handleChange = (field, value) => {
        onChange({ ...options, [field]: value });
    };

    const SelectedIcon = symbolOptions.find(s => s.value === options.symbol)?.icon || FaStar;

    return (
        <div className="rating-options">
            <div className="modal-question-manage-main-information-section">
                <label className="modal-question-manage-option-label">Scale</label>
                <div className="rating-scale-options">
                    {scaleOptions.map((scale) => (
                        <button
                            key={scale.value}
                            className={`rating-scale-button ${options.min === parseInt(scale.value.split('-')[0]) && options.max === parseInt(scale.value.split('-')[1]) ? 'selected' : ''}`}
                            onClick={() => {
                                const [min, max] = scale.value.split('-').map(Number);
                                handleChange('min', min);
                                handleChange('max', max);
                            }}
                        >
                            {scale.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className="modal-question-manage-main-information-section">
                <label className="modal-question-manage-option-label">Symbol</label>
                <div className="modal-question-manage-selection-type-container">
                    <div className="modal-question-manage-type-select-container">
                        <SelectedIcon className="modal-question-manage-type-icon" />
                        <select
                            className="modal-question-manage-type-select"
                            value={options.symbol}
                            onChange={(e) => handleChange('symbol', e.target.value)}
                        >
                            {symbolOptions.map((symbol) => (
                                <option key={symbol.value} value={symbol.value}>
                                    {symbol.label}
                                </option>
                            ))}
                        </select>
                        <FaChevronDown className="modal-question-manage-type-select-icon" />
                    </div>
                </div>
            </div>
            <div className="modal-question-manage-main-information-section-row">
                <div className="modal-question-manage-main-information-section">
                    <label className="modal-question-manage-option-label">Minimum Label</label>
                    <input
                        type="text"
                        value={options.minLabel}
                        onChange={(e) => handleChange('minLabel', e.target.value)}
                        placeholder="e.g., Poor"
                        className="modal-question-manage-input"
                    />
                </div>
                <div className="modal-question-manage-main-information-section">
                    <label className="modal-question-manage-option-label">Maximum Label</label>
                    <input
                        type="text"
                        value={options.maxLabel}
                        onChange={(e) => handleChange('maxLabel', e.target.value)}
                        placeholder="e.g., Excellent"
                        className="modal-question-manage-input"
                    />
                </div>
            </div>
        </div>
    );
};

export default RatingOptions;