import React from 'react'
import { IoClose } from "react-icons/io5";
import '../form/canvas/ModalQuestionManage.css'
import Loader from '../loaders/Loader'

export default function ModalOrgDeleteForm({ closeModal, confirmDelete, formTitle, isDeleting }) {
    return (
        <div className="modal-question-manage-overlay">
            <div className="modal-question-manage-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-question-manage-header">
                    <h3>Delete Form</h3>
                    <button className="modal-question-manage-close modal-question-manage-close-create" onClick={closeModal}>
                        <IoClose />
                    </button>
                </div>

                {!isDeleting &&
                <div className="modal-question-manage-body">
                    <div className="modal-question-manage-main-information-container">
                        <div className="modal-question-manage-main-information-section">
                            <p>Are you sure you want to delete the form "{formTitle}"?</p>
                            <p>This action cannot be undone.</p>
                        </div>
                    </div>
                </div>
                }
                {isDeleting &&
                <div className="modal-question-manage-body">
                    <Loader />
                </div>
                }
                {!isDeleting &&
                <div className="modal-question-manage-footer">
                    <button className="modal-question-manage-cancel" onClick={closeModal}>
                        Cancel
                    </button>
                    <button className="modal-question-manage-save modal-question-manage-delete" onClick={confirmDelete}>
                        Delete
                    </button>
                </div>
                }
            </div>
        </div>
    )
}
