import React, { useState, useEffect } from 'react';
import '../TheForm.css';

const TextQuestion = ({ question, value, onChange, primaryColor }) => {
    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value);
    }, [value]);

    const handleTextChange = (e) => {
        const newValue = e.target.value;
        setText(newValue);
        onChange(newValue);
    };

    const charCount = text.length;
    const maxLength = question.textOptions?.maxLength || 280;
    const percentFilled = (charCount / maxLength) * 100;

    return (
        <div className="text-question">
            {question.textOptions?.size === 'paragraph' ? (
                <textarea
                    placeholder={question.question}
                    value={text}
                    onChange={handleTextChange}
                    className="the-form-text-input the-form-textarea"
                    rows="3"
                    maxLength={maxLength}
                    style={{ '--input-border-color': primaryColor, '--input-focus-color': primaryColor }}
                />
            ) : (
                <input
                    placeholder={question.question}
                    type="text"
                    value={text}
                    onChange={handleTextChange}
                    className="the-form-text-input"
                    maxLength={maxLength}
                    style={{ '--input-border-color': primaryColor, '--input-focus-color': primaryColor }}
                />
            )}
            <div className="text-question-char-count">
                <div 
                    className="text-question-char-count-circle"
                    style={{ 
                        background: `conic-gradient(${primaryColor} ${percentFilled}%, transparent ${percentFilled}% 100%)`
                    }}
                />
                <span>{charCount}/{maxLength}</span>
            </div>
        </div>
    );
};

export default TextQuestion;