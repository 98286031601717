import React, { useState } from 'react';
import { format, addMinutes, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { COMMON_TIMEZONES } from '../utils/timeUtils';
import '../../MeetingFinder.css';

export default function TimeSlotSelection({ pollData, selectedSlots, onSelectedSlotsChange, onSubmit, onBack }) {
    const [timezone, setTimezone] = useState(pollData.timezone);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const convertToTimezone = (timestamp, fromZone, toZone) => {
        const date = new Date(timestamp);
        const targetDate = toZonedTime(date, toZone);
        const endTime = addMinutes(targetDate, pollData.duration);

        return {
            date: format(targetDate, 'yyyy-MM-dd'),
            time: format(targetDate, 'HH:mm'),
            displayTime: `${format(targetDate, 'h:mm')} - ${format(endTime, 'h:mm a')}`,
            id: timestamp
        };
    };

    const getDisplaySlots = () => {
        return pollData.proposedTimes.map(timestamp => 
            convertToTimezone(timestamp, pollData.timezone, timezone)
        );
    };

    const handleTimezoneChange = (e) => {
        setTimezone(e.target.value);
    };

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        
        if (selectedSlots.length === 0) {
            setShowConfirmModal(true);
            return;
        }

        onSubmit({
            selectedTimes: selectedSlots,
            timezone
        });
    };

    const handleConfirmEmptySubmission = () => {
        setShowConfirmModal(false);
        onSubmit({
            selectedTimes: [],
            timezone
        });
    };

    const ConfirmationModal = () => (
        <div className="meeting-finder-modal-overlay">
            <div className="meeting-finder-modal">
                <h3>Confirm Submission</h3>
                <p>Are you sure you want to submit without selecting any times?</p>
                <div className="meeting-finder-modal-actions">
                    <button 
                        onClick={() => setShowConfirmModal(false)}
                        className="meeting-finder-button-secondary"
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={handleConfirmEmptySubmission}
                        className="meeting-finder-button-primary"
                    >
                        Submit Anyway
                    </button>
                </div>
            </div>
        </div>
    );

    // Group slots by date for display
    const slotsByDate = getDisplaySlots().reduce((acc, slot) => {
        if (!acc[slot.date]) {
            acc[slot.date] = {
                displayDate: format(new Date(slot.date), 'EEEE, MMMM d'),
                slots: []
            };
        }
        acc[slot.date].slots.push(slot);
        acc[slot.date].slots.sort((a, b) => a.time.localeCompare(b.time));
        return acc;
    }, {});

    // Sort dates chronologically
    const sortedDates = Object.entries(slotsByDate)
        .sort(([dateA], [dateB]) => dateA.localeCompare(dateB));

    return (
        <div className="meeting-finder-time-selection">
            <div className="meeting-finder-time-selection-header">
                <div className="meeting-finder-timezone-selector">
                    <select value={timezone} onChange={handleTimezoneChange}>
                        {COMMON_TIMEZONES.map(tz => (
                            <option key={tz.value} value={tz.value}>
                                {tz.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="meeting-finder-dates-list">
                {sortedDates.map(([date, { displayDate, slots }]) => (
                    <div 
                        key={date} 
                        className={`meeting-finder-date-row ${sortedDates.indexOf([date]) < sortedDates.length - 1 ? 'with-border' : ''}`}
                    >
                        <div className="meeting-finder-date-column">
                            <span className="meeting-finder-day-name">
                                {format(parseISO(date), 'EEEE')}
                            </span>
                            <span className="meeting-finder-date-number">
                                {format(parseISO(date), 'MM/dd/yyyy')}
                            </span>
                        </div>
                        <div className="meeting-finder-slots-column">
                            {slots.map(({ id, displayTime }) => (
                                <div
                                    key={id}
                                    className={`meeting-finder-time-option ${selectedSlots.includes(id) ? 'selected' : ''}`}
                                    onClick={() => {
                                        onSelectedSlotsChange(prev => {
                                            if (prev.includes(id)) {
                                                const newSelection = prev.filter(slotId => slotId !== id);
                                                return newSelection;
                                            } else {
                                                const newSelection = [...prev, id];
                                                return newSelection;
                                            }
                                        });
                                    }}
                                >
                                    {displayTime}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="meeting-finder-form-buttons">
                <button type="button" onClick={onBack} className="meeting-finder-button-secondary">
                    Back
                </button>
                <button 
                    onClick={handleSubmit}
                    className="meeting-finder-button-primary"
                >
                    Next
                </button>
            </div>

            {showConfirmModal && <ConfirmationModal />}
        </div>
    );
} 