exports.trial_plans = [
    {
        tetherform_plan_ref: "launch_starter",
        display_name: "Starter",
        monthly_price: 24.99,
        annual_price: 249,
        monthly_responses: 500
    },
    {
        tetherform_plan_ref: "launch_basic",
        display_name: "Basic",
        monthly_price: 49.99,
        annual_price: 499,
        monthly_responses: 5000
    },
    {
        tetherform_plan_ref: "launch_professional",
        display_name: "Professional",
        monthly_price: 149.99,
        annual_price: 1499,
        monthly_responses: 20000
    }
]