import React from 'react'
import './ToggleSwitch.css'

export default function ToggleSwitch({ isSelected, setIsSelected }) {
    return (
        <div className={`toggle-switch ${isSelected ? 'selected' : ''}`} onClick={() => setIsSelected(!isSelected)}>
            <div className="toggle-switch-handle" />
        </div>
    );
}
