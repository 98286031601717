import React, { useState, useRef, useEffect, useCallback } from 'react'
import './OrgForms.css'
import { useOrganization } from '../../context/OrganizationContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../loaders/Loader';
import OrganizationSidebar from '../organization_sidebar/OrganizationSidebar';
import { FaPlus } from 'react-icons/fa';
import ModalOrgNewForm from './ModalOrgNewForm';
import ModalOrgDeleteForm from './ModalOrgDeleteForm';
import { createNewForm, duplicateForm, updateFormDraft } from '../../api';

export default function OrgForms() {
    const navigate = useNavigate()
    const { organization, organizationIsLoading, forms, fetchOrganizationDetails } = useOrganization()
    const [showCreateNewForm, setShowCreateNewForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formToDelete, setFormToDelete] = useState(null);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, formId: null });
    const contextMenuRef = useRef(null);

    const handleNewForm = () => {
        navigate(`/o/${organization._id}/forms/create`);
    };

    const submitNewForm = (formName) => {
        createNewForm(organization._id, { title: formName, status: "draft", structure: {} })
        .then((res) => {
            if (res.data && res.data.form && res.data.form._id) {
                navigate(`/o/${organization._id}/forms/${res.data.form._id}`)
                setShowCreateNewForm(false)
            }
        })
        .catch(() => {
            setShowCreateNewForm(false)
        })
    }

    const handleContextMenu = useCallback((e, formId) => {
        e.preventDefault();
        setContextMenu({ visible: true, x: e.clientX, y: e.clientY, formId });
    }, []);

    const handleClickOutside = useCallback((e) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
            setContextMenu(prev => ({ ...prev, visible: false }));
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleEditForm = () => {
        navigate(`/o/${organization._id}/forms/${contextMenu.formId}`);
        setContextMenu(prev => ({ ...prev, visible: false }));
    };

    const handleDuplicateForm = async () => {
        try {
            await duplicateForm(contextMenu.formId);
            await fetchOrganizationDetails(organization._id);
            setContextMenu(prev => ({ ...prev, visible: false }));
        } catch (error) {
            console.error('Error duplicating form:', error);
        }
    };

    const handleDeleteForm = async () => {
        const formToDelete = forms.find(form => form._id === contextMenu.formId);
        if (formToDelete) {
            setFormToDelete(formToDelete);
            setShowDeleteForm(true);
        }
        setContextMenu(prev => ({ ...prev, visible: false }));
    };

    const confirmDeleteForm = async () => {
        setIsDeleting(true);
        try {
            await updateFormDraft(formToDelete._id, { status: 'deleted' });
            await fetchOrganizationDetails(organization._id);
            setShowDeleteForm(false);
        } catch (error) {
            console.error('Error deleting form:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div>
            {/* MAIN */}
            {organization && !organizationIsLoading &&
            <div className="organization-forms">
                <OrganizationSidebar/>
                <div className="org-forms-main">
                    <button className="org-forms-new-button" onClick={handleNewForm}>
                        <FaPlus className="org-forms-icon" />
                        <span className="org-forms-new-button-text">New Form</span>
                    </button>
                    {showCreateNewForm && <ModalOrgNewForm createNewForm={submitNewForm} closeForm={() => setShowCreateNewForm(false)} />}
                    <div className="org-forms-list">
                        {forms.map((form) => {
                            const isClosed = form.schedule_end && new Date() > new Date(form.schedule_end);
                            const status = isClosed ? 'Closed' : (form.status === 'active' ? 'Live' : 'Draft');
                            const statusClass = status === 'Draft' ? 'badge-draft' : status === 'Live' ? 'badge-published' : 'badge-completed';
                            return (
                                <div 
                                    key={form._id} 
                                    className="org-forms-item" 
                                    onClick={(e) => {
                                        if (!contextMenu.visible) {
                                            navigate(`/o/${organization._id}/forms/${form._id}`)
                                        }
                                    }}
                                    onContextMenu={(e) => handleContextMenu(e, form._id)}
                                >
                                    {status === "Live" && <div className="org-forms-live-indicator"></div> }
                                    <span className={`form-status ${statusClass}`}>{status}</span>
                                    <h3 className="form-title">{form.title}</h3>
                                    {form.schedule_start && form.schedule_end ? (
                                        <p>{new Date(form.schedule_start).toLocaleDateString()} - {new Date(form.schedule_end).toLocaleDateString()}</p>
                                    ) : ( <p>No date set</p> )}
                                    <div className="org-form-response-count">{form.response_count || 0} responses</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            }
            {/* LOADING */}
            {organizationIsLoading &&
            <div style={{marginTop: "120px"}}>
                <Loader/>
            </div>
            }
            {contextMenu.visible && (
                <div 
                    ref={contextMenuRef}
                    className="context-menu"
                    style={{ position: 'fixed', top: contextMenu.y, left: contextMenu.x }}
                >
                    <button onClick={handleEditForm}>Edit form</button>
                    <button onClick={handleDuplicateForm}>Duplicate form</button>
                    <button onClick={handleDeleteForm}>Delete form</button>
                </div>
            )}
            {showDeleteForm && (
                <ModalOrgDeleteForm
                    closeModal={() => setShowDeleteForm(false)}
                    confirmDelete={confirmDeleteForm}
                    formTitle={formToDelete?.title}
                    isDeleting={isDeleting}
                />
            )}
        </div>
    )
}
