import React, { useState } from 'react'
import { IoChevronDown } from "react-icons/io5";
import './home.css'

export default function FAQ() {
    const faqData = [
        {
            question: "What does Tetherform do?",
            answer: "Tetherform is a survey tool that automatically transforms responses into insights. It helps professionals collect feedback, analyze it quickly, and make data-driven decisions without spending hours on spreadsheets."
        },
        {
            question: "How is Tetherform different from other survey tools?",
            answer: "Tetherform stands out by offering automatic analysis of survey responses. Instead of just collecting data, it provides actionable insights and suggestions, saving you time and helping you make informed decisions faster."
        },
        {
            question: "Is my data secure with Tetherform?",
            answer: "Yes, data security is our top priority. Tetherform uses industry-standard encryption and security measures to protect your data. All survey responses are fully anonymous by design to ensure privacy."
        },
        {
            question: "Can I integrate Tetherform with other tools?",
            answer: "While Tetherform is designed to be a comprehensive solution, we understand the need for integration. You can easily export your data and insights to use with other tools. We're also continuously working on direct integrations with popular platforms."
        },
        {
            question: "How does Tetherform's pricing model work?",
            answer: "Tetherform's pricing is based on the number of monthly responses you need. You only pay for the responses you collect, not for user seats or additional features. All plans include unlimited forms, advanced analytics, and automatic inbox delivery. Choose the plan that fits your needs, and upgrade or downgrade at any time."
        }
    ];
    return (
        <div className="home-faq-section">
            <h2>Frequently Asked Questions</h2>
            <div className="home-faq-list">
                {faqData.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    )
}

function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
                <span>{question}</span>
                <IoChevronDown className={`faq-icon ${isOpen ? 'open' : ''}`} />
            </div>
            <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
                {answer}
            </div>
        </div>
    );
}