import React from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import '../../MeetingFinder.css';

export default function JoinConfirmation({ pollData, meetingInformation }) {
    // Add this after successful submission
    const editLink = pollData.response?.current_response_secret && pollData.email ? 
        `${window.location.origin}/meeting-finder/polls/${pollData.pollId}?sauce=${pollData.response.current_response_secret}` 
        : null;

    return (
        <div className="meeting-finder-confirmation" style={{maxWidth: "800px", marginLeft: "auto", marginRight: "auto"}}>
            <div className="meeting-finder-confirmation-success">
                <IoCheckmarkCircle className="meeting-finder-confirmation-success-icon" />
                <h2>Response Submitted!</h2>
                <p>{meetingInformation.organizer.name} has been notified of your availability.</p>
            </div>

            <div className="meeting-finder-confirmation-details">
                <h3>Response Details</h3>
                <div className="meeting-finder-confirmation-info">
                    <div className="meeting-finder-confirmation-row">
                        <span>Name:</span>
                        <span>{pollData.name}</span>
                    </div>
                    {pollData.email && (
                        <div className="meeting-finder-confirmation-row">
                            <span>Email:</span>
                            <span>{pollData.email}</span>
                        </div>
                    )}
                    <div className="meeting-finder-confirmation-row">
                        <span>Available Times:</span>
                        <span>{pollData.selectedTimes.length} time slots selected</span>
                    </div>
                </div>
            </div>

            {editLink && (
                <div className="meeting-finder-confirmation-edit">
                    <p>You can edit your response later using this link:</p>
                    <input type="text" value={editLink} readOnly />
                </div>
            )}
        </div>
    );
} 