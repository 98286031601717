import React, { useState } from 'react';
import { IoCheckmarkCircle, IoCopy } from 'react-icons/io5';
import '../../MeetingFinder.css';

export default function ShareableLinkPage({ pollData, pollId }) {
    const [copied, setCopied] = useState(false);
    let baseUrl = "https://tetherform.com"
    if (process.env.NODE_ENV !== 'production') baseUrl = "http://localhost:3000"
    const shareableLink = `${baseUrl}/meeting-finder/polls/${pollId}`;

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(shareableLink);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy link:', err);
        }
    };

    return (
        <div className="meeting-finder-share">
            <div className="meeting-finder-share-success">
                <IoCheckmarkCircle className="meeting-finder-share-success-icon" />
                <h2>Poll Created Successfully!</h2>
                <p>Share this link with your participants to find the best meeting time.</p>
            </div>

            <div className="meeting-finder-share-link-container">
                <div className="meeting-finder-share-link">
                    <input 
                        type="text" 
                        value={shareableLink} 
                        readOnly 
                    />
                    <button 
                        onClick={handleCopyLink}
                        className={`meeting-finder-share-copy ${copied ? 'copied' : ''}`}
                    >
                        {copied ? 'Copied!' : (
                            <>
                                <IoCopy /> Copy
                            </>
                        )}
                    </button>
                </div>
                <div className="meeting-finder-share-expiry">
                    Expires in 7 days
                </div>
            </div>

            <div className="meeting-finder-share-details">
                <h3>Details</h3>
                <div className="meeting-finder-share-info">
                    <div className="meeting-finder-share-info-row">
                        <span>Event:</span>
                        <span>{pollData.eventName}</span>
                    </div>
                    <div className="meeting-finder-share-info-row">
                        <span>Duration:</span>
                        <span>{pollData.duration} minutes</span>
                    </div>
                    <div className="meeting-finder-share-info-row">
                        <span>Organizer:</span>
                        <span>{pollData.name}</span>
                    </div>
                    <div className="meeting-finder-share-info-row">
                        <span>Poll ID:</span>
                        <span>{pollId}</span>
                    </div>
                </div>
            </div>

        </div>
    );
} 