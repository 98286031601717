import React, { useRef, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react';
import { IoCopyOutline, IoOpenOutline } from "react-icons/io5";
import { saveAs } from 'file-saver';
import './FormSharing.css'
import './FormBuilderOptions.css';

export default function FormSharing({ formState, organizationId, formId, openPreview, openLiveForm }) {
    const [qrCodeBackgroundColor, setQrCodeBackgroundColor] = useState("transparent")
    let baseUrl = "https://tetherform.com"
    if (process.env.NODE_ENV === "development") baseUrl = "http://localhost:3000"
    const link = `${baseUrl}/f/${formId}`
    const qrRef = useRef(null);
    let primaryColor = "#309C83"
    if (formState.design_primary_color) primaryColor = formState.design_primary_color
    const downloadQRCode = () => {
      const svg = qrRef.current;
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      let fileName = "tetherform.png"
      if (formState) {
        if (formState.title) fileName = `${formState.title} QR Code.png`
      }
      img.onload = () => {
        canvas.width = 1024;
        canvas.height = 1024;
        ctx.drawImage(img, 0, 0, 1024, 1024);
        canvas.toBlob((blob) => {
          saveAs(blob, fileName);
        });
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    };
    const copyLinkToClipboard = (text) => {
        return navigator.clipboard.writeText(text)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
    const toggleQrCodeBackground = () => {
        if (qrCodeBackgroundColor === "transparent") return setQrCodeBackgroundColor("var(--color-white-pure)")
        return setQrCodeBackgroundColor("transparent")
    }
    return (
        <div className="form-builder-sharing">
            <div className="form-builder-design-options-header-row"><h2>Sharing & Distribution</h2></div>

            {formState.status === "active" &&
                <div className="form-builder-design-option">
                    <div className="form-builder-design-option-information">
                        <h4>Published Form</h4>
                        <p>Share this link to give users access to the active form</p>
                    </div>
                    <div className="form-builder-design-option-setting">
                        <div className="form-builder-sharing-link-text-container">
                            <div className="form-builder-sharing-link-text">{link}</div>
                            <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={openLiveForm}>
                                <IoOpenOutline />
                                Open
                            </div>
                            <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={() => copyLinkToClipboard(link)}>
                                <IoCopyOutline />
                                Copy
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Preview Draft</h4>
                    <p>Share this link to give users access to the preview draft of the form</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="form-builder-sharing-link-text-container">
                        <div className="form-builder-sharing-link-text">{baseUrl}/pr/{organizationId}/{formId}</div>
                        <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={openPreview}>
                            <IoOpenOutline />
                            Open
                        </div>
                        <div className="form-builder-sharing-link-text form-builder-sharing-link-copy" onClick={() => copyLinkToClipboard(`${baseUrl}/pr/${organizationId}/${formId}`)}>
                            <IoCopyOutline />
                            Copy
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>QR Code</h4>
                    <p>Use or download a QR code for rapid access and sharing</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <div className="qr-code-container">
                            <QRCodeSVG value={link} size={256} level="H" includeMargin={true} ref={qrRef} fgColor={primaryColor} bgColor={qrCodeBackgroundColor} />
                            <button onClick={downloadQRCode} className="download-button">Download</button>
                            <div style={{cursor: "pointer", fontSize: "0.8rem", color: "var(--color-grey)"}} onClick={toggleQrCodeBackground}>Toggle white background</div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="form-builder-design-option">
                <div className="form-builder-design-option-information">
                    <h4>Embed</h4>
                    <p>Render the form directly on your website</p>
                </div>
                <div className="form-builder-design-option-setting">
                    <div className="form-builder-design-option-setting-coming-soon">Coming Soon</div>
                </div>
            </div>

        </div>
    )
}
